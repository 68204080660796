import React from 'react';
import { styled } from 'linaria/react';
import ReactCookieConsent from 'react-cookie-consent';
import t from '@jetshop/intl';
import { theme } from '../../Theme';
import Link from '../../utility/Link';

const StyledLink = styled(Link)`
  color: ${theme.colors.white};
`;

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.125rem',
  lineHeight: '1.39',
  letterSpacing: '0.26px',
  backgroundColor: 'rgba( 1,16,25,0.97 )',
  zIndex: 99999
};

const buttonStyles = {
  minWidth: 120,
  padding: '12px 22px',
  color: '#ffffff',
  border: '1px solid #ffffff',
  backgroundColor: 'transparent'
};

const CookieConsent = ({ cookiePageUrl }) => {
  return (
    <ReactCookieConsent
      acceptOnScroll={false}
      buttonText="Jag accepterar"
      style={containerStyle}
      buttonStyle={buttonStyles}
    >
      {t(
        'Svenska Hem använder cookies för du ska få den bästa möjliga shoppingupplevelsen på vår webbplats. '
      )}
      {cookiePageUrl ? (
        <StyledLink to={cookiePageUrl}>
          {t.rich('Läs mer om våra cookies.')}
        </StyledLink>
      ) : null}
    </ReactCookieConsent>
  );
};

export default CookieConsent;
