import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';
import { useShopLogo } from './useShopLogo';

const StoreName = styled('h2')`
  position: relative;
  z-index: 100;

  a {
    text-decoration: none;
    color: inherit;

    svg {
      width: 70px;
      height: auto;
      max-width: 50px;
      max-height: 50px;
      transition: all 0.3s;

      ${theme.above.md} {
        max-width: 170px;
        max-height: 170px;
      }
      &.umea,
      &.wikamobler,
      &.strangnas,
      &.stegbo,
      &.asells {
        max-width: 130px;
        width: 90px;
        height: 90px;
      }

      [data-inverted-logo='true'] & {
        .rect {
          fill: none;
        }
        path,
        polygon,
        .top-bar {
          fill: ${theme.colors.textblack};
        }
      }
    }
  }
`;

const Logo = ({ onClick }) => {
  const Logo = useShopLogo();

  return (
    <StoreName>
      <Link
        className="header-logo"
        aria-label="Svenska Hem"
        to="/"
        onClick={onClick}
      >
        {Logo}
      </Link>
    </StoreName>
  );
};

export { Logo };
