import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  text-align: right;
  margin-bottom: 15px;
  color: ${theme.colors.textgrey};
  ${theme.fontSizes.description}
`;

/*==============================================================================
  # Component
==============================================================================*/

const SearchMeta = ({ page, pages }) => (
  <Wrapper>{`Sida ${page} av ${pages}`}</Wrapper>
);

export default SearchMeta;
