import React, { Fragment } from 'react';
import { Below } from '@jetshop/ui/Breakpoints';

import CartFlyout from '../../../Cart/CartFlyout';
import MobileMenu from './mobile/MobileMenu';
import DesktopMenu from './desktop/DesktopMenu';

export default function Megamenu({ navigation, usps, textColor }) {
  return (
    <Fragment>
      <Below breakpoint="lg">
        {match =>
          match ? (
            <MobileMenu usps={usps} navigation={navigation} />
          ) : (
            <DesktopMenu
              usps={usps}
              navigation={navigation}
              textColor={textColor}
            />
          )
        }
      </Below>
      <CartFlyout />
    </Fragment>
  );
}
