import React, { Component } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

import SearchResults from './SearchResults';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Flyout = styled('div')`
  position: absolute;
  min-width: 100%;
  padding-top: 20px;
  border-radius: 0px 0px 20px 20px;
  color: ${theme.colors.slategrey};
  background-color: ${theme.colors.grey};
  text-align: left;
  font-size: 1rem;
  font-family: ${theme.fonts.heading};
  letter-spacing: 1px;
  overflow: hidden;

  ${theme.below.md} {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    color: ${theme.colors.slategrey};
    text-decoration: none;
    transition: all 250ms ease;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:active,
    &:hover {
      color: ${theme.colors.black};
      background-color: ${theme.colors.lightgrey};
    }
  }
`;

/*==============================================================================
  # Components
==============================================================================*/

class SearchField extends Component {
  componentDidMount() {
    this.inputFieldRef = React.createRef();
    this.inputField = null;

    window.addEventListener('click', this.handleClick);
    window.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
    window.removeEventListener('keydown', this.handleKeydown);

    if (this.inputField) {
      this.inputField.removeEventListener('keydown', this.searchChange);

      if (this.props.searchKeyDown) {
        this.inputField.removeEventListener(
          'keydown',
          this.props.searchKeyDown
        );
      }
    }
  }

  state = {
    showResultAfter: 3,
    resultIsOpen: false
  };

  addInputfield = ref => {
    this.inputFieldRef = ref;

    if (this.inputFieldRef) {
      this.inputField = this.inputFieldRef.querySelector('input');
      this.inputField.addEventListener('keydown', this.searchChange);

      if (this.inputField && this.props.searchKeyDown) {
        this.inputField.addEventListener('keydown', this.props.searchKeyDown);
      }
    }
  };

  clearSearch = () => {
    this.setState(
      {
        resultIsOpen: false
      },
      () => {
        if (typeof document !== 'undefined' && document.activeElement) {
          document.activeElement.blur();
        }
      }
    );
  };

  handleClick = e => {
    if (typeof document !== 'undefined') {
      const banner = document.getElementById('banner');

      if (banner && !banner.contains(e.target) && this.state.resultIsOpen) {
        this.clearSearch();
      }
    }
  };

  handleKeydown = e => {
    if ((e.keyCode === 13 || e.keyCode === 27) && this.state.resultIsOpen) {
      this.clearSearch();
    }
  };

  searchChange = e => {
    if (e.target.value.length >= this.state.showResultAfter) {
      this.setState({
        resultIsOpen: true
      });
    } else {
      this.setState({
        resultIsOpen: false
      });
    }
  };

  render() {
    let {
      className,
      placeholder = '',
      searchField,
      searchResult = undefined,
      resultClick = undefined,
      searchClick = undefined
    } = this.props;

    const { resultIsOpen } = this.state;

    searchResult && searchResult(resultIsOpen);

    const inputProps = Object.assign(
      { placeholder: placeholder },
      searchField.getInputProps()
    );

    const clearField = Object.assign(
      {},
      searchField.getCancelProps({ onClick: resultClick })
    );

    return (
      <div className={className}>
        <div>
          <div
            css={{ width: '100%', height: '100%' }}
            ref={ref => this.addInputfield(ref)}
          >
            <label
              style={{
                display: 'block',
                overflow: 'hidden',
                height: 0,
                width: 0
              }}
              htmlFor="search-field"
            >
              {t('Sök')}
            </label>
            <input
              id="search-field"
              tabIndex="0"
              onClick={e => (searchClick ? searchClick(e) : null)}
              {...inputProps}
            />
          </div>
        </div>

        {resultIsOpen &&
          (searchField.result?.products.result.length > 0 ||
            searchField.result?.categories.result.length > 0) && (
            <Flyout className="flyout">
              <SearchResults
                inputField={this.inputField}
                {...clearField}
                term={searchField.term}
                result={searchField.result}
                loading={searchField.loading}
              />
            </Flyout>
          )}
      </div>
    );
  }
}

export default SearchField;
