import React from 'react';
import { styled } from 'linaria/react';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import Button from '../../ui/Button';

import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

export const ButtonFilterContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -5px;

  ${theme.above.md} {
    margin: -5px -10px;
  }
`;

const FilterButton = styled(Button)`
  display: inline-block;
  height: auto;
  width: auto;
  max-width: none;
  padding: 0px;
  margin: 5px;

  ${theme.above.md} {
    margin: 5px 10px;
  }

  div {
    padding: 10px 25px;
    text-transform: uppercase;
  }

  &:disabled {
    color: ${theme.colors.slategrey} !important;
    opacity: 0.2;
    background: transparent !important;

    .background {
      transform: scale3d(0, 1, 1);
      transform-origin: 100% 50%;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export function ButtonFilters({ className, filters }) {
  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter'
  );

  if (listFilters.length === 0) return null;

  return listFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <ButtonFilter className={className} filter={filter} />
    </ErrorBoundary>
  ));
}

function ButtonFilter({ className, filter }) {
  const { apply } = useListFilter({ filter });
  const onSelect = value => () => apply({ value });

  return (
    <ButtonFilterContainer className={className}>
      {filter.items.map(item => (
        <FilterButton
          key={item.id}
          onClick={item.resultCount > 0 && onSelect(item.value)}
          isActive={item.isActive}
          disabled={item.resultCount === 0}
        >
          {item.text}
        </FilterButton>
      ))}
    </ButtonFilterContainer>
  );
}
