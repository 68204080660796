import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Link from '../utility/Link';
import { Row, Col } from '../Layout/Container';
import { Mega, SubHeading } from './Headings';
import Text from './Text';
import { ButtonLink } from './Button';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledRow = styled(Row)``;

const Card = styled(Col)`
  > a {
    text-decoration: none;
    &:hover {
      .topTitle {
        color: ${theme.colors.slategrey};
      }
      .title {
        color: ${theme.colors.black};
      }
      img {
        //transform: scale(1.01);
      }
    }
  }

  .cardInner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    img {
      object-fit: cover !important;
      font-family: 'object-fit: cover' !important;
      transition: all 500ms ${theme.easings.secondary}, opacity 500ms ease 0s !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 10px;

    ${theme.above.sm} {
      padding-top: 15px;
    }
  }

  .topTitle,
  .title {
    transition: all 250ms ${theme.easings.secondary};
  }

  .topTitle {
    color: ${theme.colors.textgrey};
    font-weight: 400;
    letter-spacing: 1.8px;
  }

  .title {
    margin-bottom: 30px;
    color: ${theme.colors.slategrey};
  }
`;

const LargeTitle = styled(Mega)`
  margin-bottom: 30px;
`;

const StyledText = styled(Text)`
  // margin-bottom: 40px;
  margin: 2rem 0rem !important;
`;

const EntryCardLarge = styled(Col)`
  .cardInner {
    position: relative;

    .topTitle,
    .title {
      color: ${theme.colors.slategrey};
      transition: all 350ms ${theme.easings.secondary};
    }

    .title {
      margin-bottom: 0px;
    }

    &:hover {
      .topTitle,
      .title {
        color: ${theme.colors.black};
      }

      img {
        //transform: scale(1.01);
      }
    }
    &.title-html,
    &.text-html {
      h1 {
        ${theme.fontSizes.mega}
      }
      h2 {
        ${theme.fontSizes.hero}
      }
      p {
        margin: 20px auto 25px;
      }
    }
  }

  .content-large {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    padding: 80px 15px;
    color: ${theme.colors.white};
    text-decoration: none !important;

    ${theme.above.sm} {
      min-height: 450px;
    }

    ${theme.above.md} {
      justify-content: flex-end;
      min-height: 500px;
      padding: 40px 60px;
    }
  }

  .content-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    a.cover-link {
      color: ${theme.colors.white};

      ::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
      }
    }

    ${theme.above.md} {
      height: auto;
      max-width: 500px;
      margin: 0;
      text-align: left;
    }

    > .button-link {
      margin: auto;
    }
  }
`;

const ImageLarge = styled(Image)`
  position: absolute !important;
  height: 100% !important;
  padding-bottom: 0px !important;

  img {
    object-fit: cover !important;
    font-family: 'object-fit: cover' !important;
    transition: all 500ms ${theme.easings.secondary};
  }

  &.overlay {
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${theme.colors.black};
      opacity: 0.5;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
`;

/*==============================================================================
  # Component
==============================================================================*/

export const EntryContainer = ({ children, ...rest }) => {
  return <StyledRow {...rest}>{children}</StyledRow>;
};

const LinkWrapper = ({ to, asbutton, children, ...rest }) => {
  if (!to || asbutton === true) return <Fragment>{children}</Fragment>;

  return (
    <StyledLink to={to} {...rest}>
      {children}
    </StyledLink>
  );
};

export const EntryLarge = ({
  overlay = false,
  titleSize = 'lg',
  title,
  topTitle,
  text,
  link,
  image,
  titleHtml,
  textHtml,
  ...rest
}) => {
  const showTitleHtml = titleHtml && !(titleHtml?.split('<br>')?.length === 2);
  const showTextHtml = textHtml && !(textHtml?.split('<br>')?.length === 2);

  const TitleComponent = title && titleSize === 'lg' ? LargeTitle : title;

  return (
    <EntryCardLarge {...rest}>
      <div className="cardInner">
        {image && image.src && (
          <ImageLarge
            alt={title}
            {...image}
            sizes={[1, 1 / 2, 1200]}
            aspect="1200:500"
            quality={80}
            className={overlay ? 'overlay' : null}
            modifiedDate={image.modifiedDate}
          />
        )}
        <div className="content-large">
          <div className="content-inner">
            {topTitle && (
              <SubHeading className="topTitle">{topTitle}</SubHeading>
            )}
            {link ? (
              <>
                <LinkWrapper {...link} className="cover-link">
                  {showTitleHtml ? (
                    <StyledText content={titleHtml} className="title-html" />
                  ) : (
                    title && (
                      <TitleComponent className="title">{title}</TitleComponent>
                    )
                  )}
                  {showTextHtml ? (
                    <StyledText content={textHtml} className="text-html" />
                  ) : (
                    text && <StyledText className="text" content={text} />
                  )}
                </LinkWrapper>
                {link.asButton === true && (
                  <ButtonLink className="button-link" {...link} white={true}>
                    {link.text?.length ? link.text : 'Se här'}
                  </ButtonLink>
                )}
              </>
            ) : (
              <span className="cover-link">
                {showTitleHtml ? (
                  <StyledText content={titleHtml} className="title-html" />
                ) : (
                  title && (
                    <TitleComponent className="title">{title}</TitleComponent>
                  )
                )}
                {showTextHtml ? (
                  <StyledText content={textHtml} className="text-html" />
                ) : (
                  text && <StyledText className="text" content={text} />
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </EntryCardLarge>
  );
};

const EntryCard = ({
  title,
  topTitle,
  link,
  src,
  image,
  sizes,
  aspect,
  ...rest
}) => {
  //Sizes are for 3 in a row
  sizes = sizes || [1, 1 / 2, 1 / 3, 1 / 3, 1 / 3];
  aspect = aspect || '6:5';
  src = src || image?.src;

  return (
    <Card {...rest}>
      <Link {...link}>
        <div className="cardInner">
          {src && (
            <Image
              alt={title}
              {...image}
              src={src}
              sizes={sizes}
              quality={80}
              aspect={aspect}
              modifiedDate={image?.modifiedDate}
            />
          )}
          <div className="content">
            {topTitle && <h4 className="regular-size topTitle">{topTitle}</h4>}
            {title && <SubHeading className="title">{title}</SubHeading>}
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default EntryCard;
