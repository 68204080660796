import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import t, { useIntl } from '@jetshop/intl';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { useProductList } from '@jetshop/core/hooks/ProductList';

import USPBar from '../../USPBar';
import CartButton from '../../../../Cart/CartButton';
import { Logo } from '../../Logo';
import SearchBox from '../../SearchBox';
import PanelsHandler from './PanelHandler';

import { ReactComponent as MenuIcon } from '../../../../../svg/Menu.svg';
import { ReactComponent as ChevronIcon } from '../../../../../svg/Carrot.svg';
import { ReactComponent as HeartIcon } from '../../../../../svg/heart.svg';
import { ReactComponent as InspirationIcon } from '../../../../../svg/Inspiration.svg';
import { ReactComponent as MapPinAltIcon } from '../../../../../svg/MapPinAlt.svg';
import { ReactComponent as SpeechBubbleIcon } from '../../../../../svg/SpeechBubble.svg';
import { ReactComponent as PersonIcon } from '../../../../../svg/Person.svg';

import { theme } from '../../../../Theme';
import { mobileMenuDrawerId } from '../Consts';
import { HeartAnimations } from '../HeartAnimations';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const Header = styled('header')`
  position: sticky;
  z-index: 3;
  top: 0;
  display: grid;
  padding: 10px 10px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 12px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.text};
`;

const NavButtons = styled('div')`
  ul {
    display: flex;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 12px;
    border-radius: 20px;
    color: ${theme.colors.black};
    background-color: ${theme.colors.grey};
  }

  li:first-child {
    margin: 0;
  }

  ul button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    width: 100%;
    height: 100%;
  }
`;

const Search = styled(SearchBox)`
  width: 100%;
  background-color: #e4e4e4;
  border-radius: 24px;
  padding-left: 12px;
  margin-right: 12px;
  margin-bottom: 0px;
  transition: margin-left ease 0.3s;
  input {
    ::placeholder {
      color: ${theme.colors.textlight};
    }
    :focus {
      color: ${theme.colors.text};
    }
  }
`;

const MenuButton = styled('button')`
  :focus,
  :active,
  :hover {
    outline: none;
  }
`;

const SlidePanel = styled('div')`
  font-family: ${theme.fonts.heading};
  letter-spacing: 1px;
  text-transform: uppercase;

  ul + ul {
    margin-top: 2rem;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.text};
  }

  ul.categories {
    font-size: 1.5rem;
    li {
      margin: 1rem 0;
    }
    svg {
      width: 20px;
      position: relative;
      margin-left: 0.9rem;
      transform: rotate(-90deg);
    }
  }

  ul.static-links {
    font-size: 1.125rem;
  }

  ul > li {
    margin: 1.25rem 0;
    display: flex;
    svg {
      display: block;
    }

    a {
      display: flex;
      align-items: center;
    }

    span.icon {
      position: relative;
      margin-right: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: ${theme.colors.grey};
      border-radius: 50%;
      font-family: ${theme.fonts.primary};

      .favourties-count {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: -0.4em;
        width: 1.4em;
        height: 1.4em;
        border-radius: 50%;
        font-size: 0.7rem;
        line-height: 0;
        color: ${theme.colors.white};
        background-color: ${theme.colors.text};
      }
    }
  }
`;

function Panel({ isRoot, props, helpers, drawer, children }) {
  const { push, pop } = helpers;
  return (
    <SlidePanel className="panel" back={!isRoot} push={push} pop={pop}>
      {props?.children || children}
    </SlidePanel>
  );
}

function RootPanel({ props, helpers, drawer, reset }) {
  const intl = useIntl();
  const { count } = useProductList();
  const { routes } = useShopConfig();
  const { loggedIn } = useAuth();

  const { push } = helpers;

  return (
    <Panel isRoot helpers={helpers}>
      {props?.categories?.length > 0 && (
        <ul className="categories">
          {props.categories.map(({ key, label, link, children }) => (
            <li key={key}>
              <Link
                to={link}
                onClick={e => {
                  if (children.length > 1) {
                    e.preventDefault();
                    push(Panel, {
                      children: (
                        <ul className="categories">
                          <li>
                            <Link onClick={drawer.hideTarget} to={link}>
                              Allt inom {label}
                            </Link>
                          </li>
                          {children.map(({ key, label, link }) => (
                            <li key={key}>
                              <Link onClick={drawer.hideTarget} to={link}>
                                {label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )
                    });
                  } else {
                    drawer.hideTarget();
                  }
                }}
              >
                {label}
                {children.length > 1 && <ChevronIcon />}
              </Link>
            </li>
          ))}
        </ul>
      )}
      <ul className="static-links">
        <li>
          <Link
            onClick={drawer.hideTarget}
            to={routes.favourites.path}
            aria-label={intl('Favourites')}
          >
            <span className="icon">
              <span className="favourties-count">{count}</span>
              <HeartIcon />
            </span>
            {t('Favourites')}
          </Link>
        </li>
        <li>
          <Link
            onClick={drawer.hideTarget}
            to={routes.inspiration.path}
            aria-label={intl('Inspiration')}
          >
            <span className="icon">
              <InspirationIcon />
            </span>
            {t('Inspiration')}
          </Link>
        </li>
        <li>
          <Link
            onClick={drawer.hideTarget}
            to={routes.stores.path}
            aria-label={intl('Butiker')}
          >
            <span className="icon">
              <MapPinAltIcon />
            </span>
            {t('Butiker')}
          </Link>
        </li>
        <li>
          <Link
            onClick={drawer.hideTarget}
            to={routes.customerService.path}
            aria-label={intl('Customer service')}
          >
            <span className="icon">
              <SpeechBubbleIcon />
            </span>
            {t('Kundtjänst')}
          </Link>
        </li>
        <li>
          {loggedIn ? (
            <Link to={routes.myPages.path} onClick={drawer.hideTarget}>
              <span className="icon">
                <PersonIcon />
              </span>
              {t('My Pages')}
            </Link>
          ) : (
            <Link to={routes.login.path} onClick={drawer.hideTarget}>
              <span className="icon">
                <PersonIcon />
              </span>
              {t('Log in')}
            </Link>
          )}
        </li>
      </ul>
    </Panel>
  );
}

export default function MobileMenu({ usps, navigation }) {
  return (
    <Fragment>
      <USPBar usps={usps}></USPBar>
      <Header>
        <Logo />
        <Search />
        <NavButtons>
          <ul>
            <li>
              <CartButton />
            </li>
            <li>
              <DrawerTrigger id={mobileMenuDrawerId}>
                {menuDrawer => (
                  <MenuButton aria-label="Menu" onClick={menuDrawer.showTarget}>
                    <HeartAnimations />
                    <MenuIcon />
                  </MenuButton>
                )}
              </DrawerTrigger>
            </li>
          </ul>
        </NavButtons>
      </Header>
      <DrawerTarget id={mobileMenuDrawerId}>
        {menuDrawer => (
          <Drawer isOpen={menuDrawer.isOpen} right>
            <PanelsHandler
              reset={300}
              drawer={menuDrawer}
              root={{
                component: RootPanel,
                props: { categories: navigation || [] }
              }}
            />
          </Drawer>
        )}
      </DrawerTarget>
    </Fragment>
  );
}
