import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Link from '../utility/Link';

/*==============================================================================
  # Styles
==============================================================================*/

const BrandWrapper = styled('div')`
  width: ${({ width }) => (width ? `${width}px` : `100px`)};
`;

const Logo = styled(Image)`
  width: 100% !important;
  height: 0% !important;
  padding-bottom: 50% !important;
`;

/*==============================================================================
  # Component
==============================================================================*/

const LinkWrap = ({ children, to, title, target }) => {
  return to ? (
    <Link title={title} to={to} target={target}>
      {children}
    </Link>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const BrandLogo = ({ width, title, to, target, src, alt, ...props }) => {
  let imageSizes = [width];

  return (
    <BrandWrapper width={width} {...props}>
      <LinkWrap title={title} to={to} target={target}>
        {src && (
          <Logo src={src} alt={alt || title} sizes={imageSizes} aspect="1:1" />
        )}
      </LinkWrap>
    </BrandWrapper>
  );
};

export default BrandLogo;
