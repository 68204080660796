import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Link = props => {

  const { children, to, href, target, targetBlank, className, dark, ...rest } = props;
  const location = useLocation();

  let theHref = to || href;
  const attrTarget = target || targetBlank ? { target: '_blank' } : {};

  const attrRel = target || targetBlank ? { rel: 'noopener noreferrer' } : {};

  if (theHref && theHref.includes(location.origin)) {
    theHref = theHref.replace(new RegExp(location.origin, 'g'), '');
  }

  const internal = theHref && !theHref.includes('http');

  //Use RouterLink for internal links
  if (internal) {

    return (
      <RouterLink to={theHref} className={className} {...rest}>
        {children}
      </RouterLink>
    );

    //Use regular a-tag for external links
  } else {

    return (
      <a href={theHref} className={className} {...attrTarget} {...attrRel}>
        {children}
      </a>
    );

  }
}
export default Link;
