import React from 'react';
import t from '@jetshop/intl';
import { Label } from './ListFilters';
import {
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { FilterDropdownStyles as FilterDropdownMenu } from './FilterDropdownStyles';
import { StyledCheck } from '@jetshop/ui/Select/Components';
import { useSortOrder } from '@jetshop/core/hooks/Filters/useSortOrder';

/*==============================================================================
  # Component
==============================================================================*/

export function SortDropdown({ sortOrders }) {
  const {
    mappedSortOrder,
    changeSortOrder,
    sortOrder,
    direction
  } = useSortOrder({ sortOrders });

  const activeItem = mappedSortOrder.find(
    item => item.direction === direction && item.value === sortOrder
  );

  return (
    <FilterDropdownMenu style={{ marginRight: 0 }}>
      <Label>{t('Sortering')}</Label>
      <DropdownMenuButton>
        <span>{activeItem ? activeItem.text : t('Sortera efter')}</span>
        <span className="marker" />
      </DropdownMenuButton>
      <DropdownMenuItems>
        {mappedSortOrder.map(sortItem => {
          return (
            <DropdownMenuItem
              onSelect={() =>
                changeSortOrder(sortItem.value, sortItem.direction)
              }
              key={`${sortItem.value}-${sortItem.direction}`}
            >
              {sortItem.text}
              {activeItem === sortItem && <StyledCheck />}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuItems>
    </FilterDropdownMenu>
  );
}
