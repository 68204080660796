import React from 'react';

export const SuperMega = ({ size = 'h2', children, className, ...rest }) => {
  const Tag = size;
  let classNames = ['super-mega-size', className].join(' ');
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};

export const Mega = ({ size = 'h2', children, className, ...rest }) => {
  const Tag = size;
  let classNames = ['mega-size', className].join(' ');
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};

export const Hero = ({ size = 'h2', children, className, ...rest }) => {
  const Tag = size;
  let classNames = ['hero-size', className].join(' ');
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};

export const Heading = ({ size = 'h3', children, className, ...rest }) => {
  const Tag = size;
  let classNames = ['heading-size', className].join(' ');
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};

export const SubHeading = ({ size = 'h3', children, className, ...rest }) => {
  const Tag = size;
  let classNames = ['sub-heading-size', className].join(' ');
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};

export const SmallHeading = ({ size = 'h4', children, className, ...rest }) => {
  const Tag = size;
  let classNames = ['small-heading-size', className].join(' ');
  return (
    <Tag className={classNames} {...rest}>
      {children}
    </Tag>
  );
};

export const variableHeadingTag = tag => {
  const tags = {
    SuperMega,
    Mega,
    Hero,
    Heading,
    SubHeading,
    SmallHeading
  };
  return tags[tag];
};

export const getSize = title_size => {
  switch (title_size) {
    case 'sm':
      return 'Heading';
    case 'lg':
      return 'Mega';
    case 'xl':
      return 'SuperMega';
    default:
      return 'Hero';
  }
};
