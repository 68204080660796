import React, { Component } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { PaginationWrapper } from '@jetshop/ui/Pagination/Pagination';
import { PaginationBehaviour } from '@jetshop/ui/Pagination';
import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled(PaginationWrapper)`
  display: block;
  text-align: center;
  margin-top: 50px;

  ${theme.above.sm} {
    margin-bottom: 50px;
  }
`;

const PaginationRow = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: -5px;

  ${theme.below.sm} {
    margin: -2px;
  }
`;

const Button = styled('button')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 5px;
  border: 1px solid ${theme.colors.black};
  color: ${theme.colors.black};
  background: transparent;
  outline: 0;
  cursor: pointer;
  transition: all 250ms ease;

  ${theme.below.sm} {
    width: 30px;
    height: 30px;
    margin: 2px;
    ${theme.fontSizes.description}
  }

  &:not([disabled]):hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  }

  &[disabled] {
    cursor: default;
  }

  &.current {
    cursor: default;
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  }
`;

const NextButton = styled(Button)`
  border: 0px;
  color: ${theme.colors.black} !important;
  background-color: transparent !important;

  &[disabled] {
    opacity: 0.5;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0px;
    transform: translate(-50%, -50%);
  }
`;

const PrevButton = styled(NextButton)`
  span {
    transform: translate(-50%, -50%) rotate(180deg);
  }
`;

const Arrow = styled('span')`
  display: block;
  position: relative;
  width: 15px;
  height: 15px;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-right: 1px solid ${theme.colors.black};
    border-bottom: 1px solid ${theme.colors.black};
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export const customScrollReset = () =>
  typeof window !== 'undefined' ? window.scrollTo(0, 0) : false;

class PaginationButton extends Component {
  handleOnClick = () => {
    const { label, current, disabled, goToPage } = this.props;

    customScrollReset();

    if (label && goToPage && !disabled && !current) {
      this.props.goToPage(parseInt(label));
    }
  };

  render() {
    const { label, disabled, current, className = '', ...rest } = this.props;
    const currentClassName = current ? 'current' : '';

    return (
      <Button
        {...rest}
        className={cx(className, currentClassName)}
        disabled={disabled}
        onClick={() => this.handleOnClick()}
      >
        {label}
      </Button>
    );
  }
}

const PaginationComponent = ({ totalResults }) => (
  <PaginationContext.Consumer>
    {props => (
      <PaginationBehaviour {...props} total={totalResults}>
        {props => {
          const {
            isFirstPage,
            isLastPage,
            prevPage,
            nextPage,
            goToPage,
            currentPage,
            totalPages,
            hasPages
          } = props;

          const siblings = 2;
          let buttons = [];

          //Add buttons for nearest pages
          for (
            var i = currentPage - siblings;
            i < currentPage + (siblings + 1);
            i++
          ) {
            if (i < 1 || i > totalPages) {
              continue;
            }

            buttons.push(i);
          }

          //Add button for first page if necessary
          if (buttons[0] !== 1) {
            if (buttons[0] !== 2) {
              buttons.unshift('...');
            }
            buttons.unshift(1);
          }

          //Add button for last page if necessary
          if (buttons[buttons.length - 1] !== totalPages) {
            if (buttons[buttons.length - 1] !== totalPages - 1) {
              buttons.push('...');
            }
            buttons.push(totalPages);
          }

          return hasPages && totalPages > 1 ? (
            <Wrapper>
              <PaginationRow>
                <PrevButton
                  disabled={isFirstPage}
                  onClick={() => {
                    customScrollReset();
                    prevPage();
                  }}
                >
                  <Arrow />
                </PrevButton>

                {buttons &&
                  buttons.map((label, i) => (
                    <PaginationButton
                      key={i}
                      label={label}
                      current={label === currentPage}
                      disabled={label === '...'}
                      goToPage={goToPage}
                    />
                  ))}

                <NextButton
                  disabled={isLastPage}
                  onClick={() => {
                    customScrollReset();
                    nextPage();
                  }}
                >
                  <Arrow />
                </NextButton>
              </PaginationRow>
            </Wrapper>
          ) : null;
        }}
      </PaginationBehaviour>
    )}
  </PaginationContext.Consumer>
);

export default PaginationComponent;
