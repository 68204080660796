import React from 'react';
import { styled } from 'linaria/react';
import { getSize, variableHeadingTag } from './Headings';
import { ButtonLink } from './Button';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Section = styled('div')`
  display: inline-block;
  width: 100%;
  color: inherit;

  ${theme.above.sm} {
    max-width: 740px;

    &.small {
      max-width: 540px;
    }

    &.full {
      max-width: none;
    }
  }

  ${theme.above.lg} {
    &.wide {
      max-width: 1100px;
    }
  }

  .title {
    margin-bottom: 20px;

    ${theme.above.md} {
      margin-bottom: 30px;
    }

    ${theme.above.lg} {
      margin-bottom: 40px;
    }
  }

  .buttonlink {
    display: inline-block;
    margin-top: 30px;

    ${theme.above.md} {
      margin-top: 40px;
    }

    ${theme.above.lg} {
      margin-top: 50px;
    }
  }
`;

export const TextStyle = `
  color: inherit;

  ${theme.fontSizes.regular}

  ${theme.above.sm} {
    max-width: 740px;

    &.small {
      max-width: 540px;
    }

    &.full {
      max-width: none;
    }
  }

  ${theme.above.md} {
    &.largeText {
      ${theme.fontSizes.heading}
    }
  }

  ${theme.above.lg} {
    &.wide {
      max-width: 1100px;
    }
  }

  ul,
  ol {
    padding-left: 3px;
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  p,
  ul,
  ol {
    margin-bottom: 10px;
  }

  hr {
    border-top: 1px solid currentColor;
  }

  strong {
    font-weight: 700;
  }

  blockquote {
    display: block;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    ${theme.fontSizes.heading}

    p:last-of-type {
      margin-bottom: 0px;
    }

    &::before,
    &::after {
      display: block;
      width: 100%;
      content: '“';
      max-width: 700px;
      margin: 10px auto 0px;
      text-align: center;
      text-transform: uppercase;
      ${theme.fontSizes.heading}
    }
  }

  a {
    color: currentColor;
    text-decoration: underline;

    &.phone,
    &.mail {
      color: currentColor;
      text-decoration: none;
    }
  }
`;

const StyledText = styled('div')`
  ${TextStyle}

  img {
    display: inline-block;
    max-width: 100%;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export const TextSection = ({
  className,
  title,
  titleSize,
  text,
  small,
  wide,
  full,
  largeText,
  link,
  ...rest
}) => {
  const Title = variableHeadingTag(getSize(titleSize));

  let classes = '';
  classes += className;
  if (small) classes += ' small';
  if (wide) classes += ' wide';
  if (full) classes += ' full';

  return (
    <Section className={classes} titleSize={titleSize} {...rest}>
      {title && <Title className="title">{title}</Title>}

      <Text
        className="textsection"
        small={small}
        wide={wide}
        full={full}
        largeText={largeText}
        content={text}
      />

      {link && (
        <div className="buttonlink">
          <ButtonLink dark={link.dark} {...link}>
            {link.title}
          </ButtonLink>
        </div>
      )}
    </Section>
  );
};

const Text = ({ className, content, small, wide, full, largeText }) => {
  let text = content ? content : '';

  let classes = '';
  classes += className;
  if (small) classes += ' small';
  if (wide) classes += ' wide';
  if (full) classes += ' full';
  if (largeText) classes += ' largeText';

  return (
    <StyledText
      className={classes}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default Text;
