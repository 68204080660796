import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { ToastContainer } from 'react-toastify';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastWrapper from './ToastWrapper';
import cartQuery from './CartQuery.gql';
import { theme } from '../Theme';
import { ReactComponent as ShoppingBag } from '../../svg/shopping-bag.svg';

/*==============================================================================
  # Styles
==============================================================================*/

const Button = styled('button')`
  background-color: transparent;

  span,
  svg {
    display: block;
    height: 18px;
  }

  &:focus,
  > span:focus {
    outline: 0;
  }

  &:focus > span {
    box-shadow: 0 0 2px 2px #51a7e8;
  }
`;

export const Quantity = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.35em;
  right: -0.7em;
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  font-size: 0.7rem;
  line-height: 0;
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.white};
  background-color: ${theme.colors.text};
`;

/*==============================================================================
  # Component
==============================================================================*/

export const Icon = ({ items }) => {
  const maxQuantity = 9;

  return (
    <span>
      {items > 0 && (
        <Quantity>{items > maxQuantity ? `${maxQuantity}+` : items}</Quantity>
      )}
      <ShoppingBag />
    </span>
  );
};

const CartButton = () => {
  return (
    <Fragment>
      <ToastWrapper>
        <ToastContainer
          hideProgressBar
          autoClose={2000}
          closeButton={false}
          closeOnClick={true}
        />
      </ToastWrapper>

      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const items = result?.data?.cart?.totalQuantity || 0;

          return (
            // Use a flyout for breakpoints above md
            // Else use a Drawer
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {drawer => (
                <Button
                  id="cart-btn"
                  data-testid="header-cart"
                  aria-label="Cart"
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  <span tabIndex="-1">
                    <Icon items={items} />
                  </span>
                </Button>
              )}
            </DrawerTrigger>
          );
        }}
      </CartProvider>
    </Fragment>
  );
};

export default CartButton;
