import React from 'react';
// import SuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';
import SuggestedTerm from './CustomSuggestedTerm';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';

const SearchResultsWrapper = styled.div`
  h2 {
    padding: 5px 10px;
  }
`;

const SearchResults = ({ term, result, loading, onClick }) => {
  const products = result.products;
  const categories = result.categories;

  if (
    !loading &&
    products.result.length === 0 &&
    categories?.result.length === 0
  ) {
    return null;
  }

  return (
    <SearchResultsWrapper>
      {products.result.length > 0 && (
        <>
          <h2>{t('Products')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {products.result.map(item => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}

      {categories?.result.length > 0 && (
        <>
          <h2>{t('Categories')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {categories.result.map(item => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}
    </SearchResultsWrapper>
  );
};

export default SearchResults;
