import DINEot from './DINEngschriftLT-Alternate.eot';
import DINWoff from './DINEngschriftLT-Alternate.woff';
import DINWoff2 from './DINEngschriftLT-Alternate.woff2';
import DINTtf from './DINEngschriftLT-Alternate.ttf';
import DINSvg from './DINEngschriftLT-Alternate.svg';

import OSEot from './open-sans-v17-latin-regular.eot';
import OSWoff from './open-sans-v17-latin-regular.woff';
import OSWoff2 from './open-sans-v17-latin-regular.woff2';
import OSTtf from './open-sans-v17-latin-regular.ttf';
import OSSvg from './open-sans-v17-latin-regular.svg';

import OSEotItalic from './open-sans-v17-latin-italic.eot';
import OSWoffItalic from './open-sans-v17-latin-italic.woff';
import OSWoff2Italic from './open-sans-v17-latin-italic.woff2';
import OSTtfItalic from './open-sans-v17-latin-italic.ttf';
import OSSvgItalic from './open-sans-v17-latin-italic.svg';

import OSEotBold from './open-sans-v17-latin-700.eot';
import OSWoffBold from './open-sans-v17-latin-700.woff';
import OSWoff2Bold from './open-sans-v17-latin-700.woff2';
import OSTtfBold from './open-sans-v17-latin-700.ttf';
import OSSvgBold from './open-sans-v17-latin-700.svg';

import { css } from 'linaria';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
  :global(html){
  /* DINEngschriftLT-Alternate */
    @font-face {
      font-family: 'DINEngschriftLT-Alternate';
      font-display: block;
      font-style: normal;
      font-weight: 400;
      src: url('${DINEot}'); /* IE9 Compat Modes */
      src: local('DINEngschriftLT-Alternate'), local('DINEngschriftLT Alternate'),
          url('${DINEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('${DINWoff2}') format('woff2'), /* Super Modern Browsers */
          url('${DINWoff}') format('woff'), /* Modern Browsers */
          url('${DINTtf}') format('truetype'), /* Safari, Android, iOS */
          url('${DINSvg}#DINEngschriftLT-Alternate') format('svg'); /* Legacy iOS */
    }
    /* open-sans-regular - latin */
    @font-face {
      font-family: 'Open Sans';
      font-display: swap;
      font-style: normal;
      font-weight: 400;
      src: url('${OSEot}'); /* IE9 Compat Modes */
      src: local('Open Sans Regular'), local('OpenSans-Regular'),
          url('${OSEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('${OSWoff2}') format('woff2'), /* Super Modern Browsers */
          url('${OSWoff}') format('woff'), /* Modern Browsers */
          url('${OSTtf}') format('truetype'), /* Safari, Android, iOS */
          url('${OSSvg}#OpenSans') format('svg'); /* Legacy iOS */
    }
    /* open-sans-italic - latin */
    @font-face {
      font-family: 'Open Sans';
      font-display: swap;
      font-style: italic;
      font-weight: 400;
      src: url('${OSEotItalic}'); /* IE9 Compat Modes */
      src: local('Open Sans Italic'), local('OpenSans-Italic'),
          url('${OSEotItalic}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('${OSWoff2Italic}') format('woff2'), /* Super Modern Browsers */
          url('${OSWoffItalic}') format('woff'), /* Modern Browsers */
          url('${OSTtfItalic}') format('truetype'), /* Safari, Android, iOS */
          url('${OSSvgItalic}#OpenSans') format('svg'); /* Legacy iOS */
    }
    /* open-sans-700 - latin */
    @font-face {
      font-family: 'Open Sans';
      font-display: swap;
      font-style: normal;
      font-weight: 700;
      src: url('${OSEotBold}'); /* IE9 Compat Modes */
      src: local('Open Sans Bold'), local('OpenSans-Bold'),
          url('${OSEotBold}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('${OSWoff2Bold}') format('woff2'), /* Super Modern Browsers */
          url('${OSWoffBold}') format('woff'), /* Modern Browsers */
          url('${OSTtfBold}') format('truetype'), /* Safari, Android, iOS */
          url('${OSSvgBold}#OpenSans') format('svg'); /* Legacy iOS */
    }
  }
`;
}
