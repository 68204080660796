import React from 'react';
import { styled } from 'linaria/react';

const Wrapper = styled('div')`
  width: 100%;
  padding: 2rem;
  max-width: 30rem;
  margin: auto;

  h1 {
    font-size: 5rem;
  }
`;

const MaintenancePage = () => {
  return (
    <Wrapper>
      <h1>Ursäkta!</h1>
      <h2>
        Vi jobbar just nu med att förbättra sidan.
        <br />
        Välkommen tillbaka efter kl 10.00.
      </h2>
    </Wrapper>
  );
};

export default MaintenancePage;
