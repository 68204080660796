import React from 'react';
import { styled } from 'linaria/react';
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Price from '../Price';
import CloseButton from '../ui/CloseButton';
import { theme } from '../Theme';
import removeFromCartMutation from './removeFromCart.gql';
import setQuantityMutation from './setQuantity.gql';
import cartQuery from './CartQuery.gql';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  display: flex;
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${theme.colors.lightgrey};
`;

const ImageContainer = styled('div')`
  display: flex;
  width: 40px;
  align-items: center;
`;

const Title = styled(ProductLink)`
  color: ${theme.colors.black} !important;
  text-decoration: none;
  font-weight: 700;
  ${theme.fontSizes.regular}
`;

const ProductDetail = styled('section')`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  padding-left: 10px;
`;

const ItemDetails = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 35px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const VariationName = styled(Title)`
  margin-top: 5px;
  text-transform: none;

  span {
    text-transform: uppercase;
  }
`;

const RemoveItem = styled(CloseButton)`
  position: absolute;
  top: 2px;
  right: 0px;
`;

const PriceWrapper = styled('div')`
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
`;

const AdjustQty = styled('div')`
  display: flex;
  align-items: center;

  span {
    line-height: 30px;
    display: inline-block;

    &:nth-of-type(2) {
      margin: 0 10px;
    }
  }
`;

const DecrementBtn = styled(CloseButton)`
  transform: rotate(-45deg);

  &::before {
    width: 35%;
    height: 1px;
  }

  &::after {
    display: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const IncrementBtn = styled(CloseButton)`
  transform: rotate(45deg);

  &::before,
  &::after {
    width: 50%;
    height: 1px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const CartItem = ({ item, modal = null, className = '' }) => {
  const { isVariant, variantImage, variantValues } = getCartItemVariant(item);

  const { setQuantity } = useSetQuantity({
    setQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  let { distributionPackageSize } = item.product;

  const productImage = variantImage || item?.product?.images?.[0];

  return (
    <Wrapper className={className} title={item.product.name}>
      <ImageContainer>
        {item.product.images.length > 0 && (
          <Image
            aspect="1:1"
            sizes={40}
            src={productImage.url}
            alt={productImage.alt}
            modifiedDate={productImage.modifiedDate}
          />
        )}
      </ImageContainer>

      <ProductDetail>
        <ItemDetails>
          <Title
            product={item.product}
            onClick={() => {
              if (modal) {
                modal.hideTarget();
              }
            }}
          >
            <span>{item.product.name}</span>
            {item.product.canonicalCategory
              ? ` - ${item.product.canonicalCategory.name}`
              : ''}
          </Title>

          {isVariant && (
            <VariationName title={variantValues.join(', ')}>
              {variantValues.join(', ')}
            </VariationName>
          )}

          <RemoveItem
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          />
        </ItemDetails>

        <PriceWrapper>
          <AdjustQty>
            <DecrementBtn
              data-testid="decrement-quantity"
              disabled={item.quantity <= distributionPackageSize}
              onClick={() =>
                item.quantity !== distributionPackageSize &&
                setQuantity({
                  itemId: item.id,
                  quantity: item.quantity - distributionPackageSize
                })
              }
            />
            <span>{item.quantity}</span>
            <IncrementBtn
              data-testid="increment-quantity"
              onClick={() =>
                setQuantity({
                  itemId: item.id,
                  quantity: item.quantity + distributionPackageSize
                })
              }
            />
          </AdjustQty>

          <Price price={item.total} />
        </PriceWrapper>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
