import React, { Component } from 'react';
import { styled } from 'linaria/react';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import SearchField from './SearchField';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { ReactComponent as SearchIconSvg } from '../../../svg/Search.svg';
import { ReactComponent as CrossIconSvg } from '../../../svg/Cross.svg';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const SearchIcon = styled(SearchIconSvg)`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  cursor: pointer;

  path {
    fill: currentColor;
  }

  ${theme.below.lg} {
    right: 10px;
  }
`;

const CrossIcon = styled(CrossIconSvg)`
  position: absolute;
  top: 50%;
  right: 38px;
  width: 13px !important;
  height: 13px !important;
  transform: translateY(-50%);
  cursor: pointer;

  ${theme.below.lg} {
    right: 33px;
  }
`;

const SearchContainer = styled('div')`
  position: relative;
  background-color: ${theme.colors.lightgrey};

  input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-right: 40px;
    color: inherit;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none;
    ${theme.fontSizes.regular}
    font-family: ${theme.fonts.heading};
    letter-spacing: 1px;

    ::placeholder {
      color: inherit;
      opacity: 1;
    }
    
    -webkit-appearance: none;

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }

    ${theme.below.lg} {
      padding: .5rem 0;
      color: ${theme.colors.mediumdarkgrey};
    }

  }
`;

const StyledSearchField = styled(SearchField)`
  width: 100%;
`;

/*==============================================================================
  # Component
==============================================================================*/

class SearchBox extends Component {
  handleRef = r => {
    this.ref = r;
    if (this.props.childRef) {
      this.props.childRef(r);
    }
  };

  simulateKeyDown = () => {
    if (typeof window !== 'undefined') {
      const keyPress = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        keyCode: 13
      });

      if (this.ref) {
        let input = this.ref.querySelector('input');
        input.dispatchEvent(keyPress);
      }
    }
  };

  resetInputValue = () => (this.ref.querySelector('input').value = '');

  onCancel = () => {
    //if (this.props.closeMobileSearch) this.props.closeMobileSearch(); <-- uncomment if they decide to not have search always open on mobile
  };

  render() {
    let { ...rest } = this.props;

    return (
      <SearchAutoCompleteContainer
        autocompleteQuery={AutocompleteQuery}
        initialValue=""
        onCancel={this.onCancel}
        onSubmit={this.resetInputValue}
      >
        {searchField => {
          return (
            <SearchContainer
              id="hr-search"
              ref={r => this.handleRef(r)}
              {...rest}
            >
              {searchField.getInputProps().value.length > 0 && (
                <CrossIcon {...searchField.getCancelProps()} />
              )}

              <StyledSearchField
                placeholder={'Sök produkter'}
                searchField={searchField}
              />

              <SearchIcon onClick={() => this.simulateKeyDown()} />
            </SearchContainer>
          );
        }}
      </SearchAutoCompleteContainer>
    );
  }
}

SearchBox.SearchField = StyledSearchField;
SearchBox.SearchIcon = SearchIcon;
export default SearchBox;
