import { megamenuCategoryIdentifier } from '../../Layout/Header/Megamenu/Consts';
import { randomKey } from '../functions';

const convertToMegaMenuCategory = category => {
  let data, link, label, customOverview, hasHero;
  switch (category?.type) {
    case 'megamenuCategory':
      link = category.properties.find(x => x.name === 'overrideLink')?.value
        ?.value;

      label = category.properties.find(x => x.name === 'overrideText')?.value
        ?.value;

      customOverview = category.properties.find(
        x => x.name === 'customOverview'
      )?.value?.boolValue;

      hasHero = category.properties.find(x => x.name === 'hasHero')?.value
        ?.boolValue;

      const image = category.properties.find(x => x.name === 'image')?.value
        ?.value;

      data = {
        label,
        link,
        children: (category?.children || [])
          .map(convertToMegaMenuCategory)
          .filter(Boolean),
        images: { src: image, alt: label },
        customOverview: !!customOverview,
        hasHero: !!hasHero
      };
      break;
    case 'megamenuSubCategory':
      const props = category?.properties?.[0]?.value;
      if (!props) return;

      data = {
        label: props.name,
        link: props.primaryRoute.path,
        children: (props.subcategories || [])
          .map(category => ({ ...category, type: 'category' }))
          .map(convertToMegaMenuCategory),
        images: props.images
      };
      break;
    case 'category':
      data = {
        label: category.name,
        link: category.primaryRoute.path,
        children: (category?.children || [])
          .map(convertToMegaMenuCategory)
          .filter(Boolean),
        images: []
      };
      break;
    default:
      return;
  }

  if (!data) return;

  return {
    key: randomKey(),
    ...data
  };
};

export const convertToMegaMenucategories = categories => {
  if (!Array.isArray(categories)) return;
  return categories
    .filter(({ type }) => type === megamenuCategoryIdentifier)
    .map(convertToMegaMenuCategory)
    .filter(Boolean);
};
