import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import CookieConsent from './CookieConsent';
import Container from '../Container';
import Brands from '../../Sections/Brands';
import Articles from '../../Sections/Articles';
import TextPlate from '../../Sections/TextPlate';
import Text from '../../Sections/Text';
import Entries from '../../Sections/Entries';
import Newsletter from '../../Sections/Newsletter';
import FooterLinks from './FooterLinks';
import LoadingPage from '../../LoadingPage';
import { HTML2JSON } from '../../utility/functions';
import prepareInspirationCards from '../../Inspiration/PrepareInspirationCards';
import { ReactComponent as Logo } from '../../../svg/Logotype.svg';
import { ReactComponent as Klarna } from '../../../svg/klarna-ruta.svg';
import { theme } from '../../Theme';
import footerQuery from './FooterQuery.gql';
import striptags from 'striptags';

/*==============================================================================
  # Styles
==============================================================================*/

const FooterBaseContainer = styled('div')`
  max-width: 100%;
  margin-top: 50px;
`;

const FooterContainer = styled(Container)`
  position: relative;
  max-width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.slategrey};

  > div {
    ${theme.above.lg} {
      flex-direction: row;
    }
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${theme.colors.slategrey};
  }

  .klarna {
    width: 300px;
    margin: 50px auto 0px;

    ${theme.above.md} {
      width: 500px;
    }
  }
`;

const LogoPlaceholder = styled(Logo)`
  width: 75px;
  height: 75px;
  margin-bottom: 60px;

  ${theme.below.sm} {
    order: 2;
    margin: 30px auto 0px;
  }

  ${theme.above.lg} {
    margin-right: 50px;
  }
`;

const StyledFooterLinks = styled(FooterLinks)`
  ${theme.below.sm} {
    order: 1;
  }

  ${theme.above.lg} {
    width: calc(100% - 125px);
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const orderArticles = (articles, currentArticleCategoty, pathname) => {
  if (!articles) return null;

  //Filter for right ammount
  let articlesNum = 0;
  articles = articles.filter((article, i) => {
    const isCurrentArticle = article.link.to === pathname;

    if (articlesNum < 4 && !isCurrentArticle) {
      articlesNum++;
      return true;
    } else {
      return false;
    }
  });

  //Return
  return articles;
};

const ContentFooter = ({
  data,
  inspirationPage,
  inspirationCategoryId,
  brandsPage
}) => {
  const location = useLocation();
  if (!data) return null;

  let json = HTML2JSON(data.page.content, 'footer');
  let brands = HTML2JSON(data.brandsPage && data.brandsPage.content, 'brands');

  const preparedCards = prepareInspirationCards(
    data?.categories || [],
    inspirationCategoryId
  );
  let { articles } = preparedCards;
  let currentArticleCategoty = 1;
  articles = orderArticles(articles, currentArticleCategoty, location.pathname);

  let showBrands = location.pathname !== brandsPage.path;
  let showArticles = location.pathname !== inspirationPage.path;
  let showAboutText = true;
  let showEntries = true;
  let showNewsletter = true;

  const aboutText = json?.aboutText ?? {};
  const entries = json?.entries ?? [];
  const newsletter = json?.newsletter ?? [];

  const brandsText = brands?.text ?? [];
  const brandsList = brands?.brandsList ?? [];

  return (
    <FooterBaseContainer>
      {showBrands && (
        <div
          css={css`
            margin-bottom: 50px;
          `}
        >
          <Text
            alignment={brandsText.alignment}
            text={brandsText.text}
            title={brandsText.title}
            titleSize={brandsText.titleSize}
            wide={brandsText.wide}
            marginTop={false}
          />
          <Brands {...brandsList} />
        </div>
      )}

      {showArticles && (
        <Articles
          title="Inspiration"
          link={{
            to: inspirationPage.path,
            title: 'Mer inspiration'
          }}
          entries={articles}
          marginTop={false}
        />
      )}

      {showAboutText && aboutText && <TextPlate {...aboutText} />}

      {showEntries && entries && <Entries entries={entries} marginTop={true} />}

      {showNewsletter && newsletter && <Newsletter {...newsletter} />}
    </FooterBaseContainer>
  );
};

const MainFooter = ({ linkGroups, socialmedia }) => (
  <FooterContainer id="footer" fullWidth={true} marginTop="md">
    <Container>
      <LogoPlaceholder />

      <StyledFooterLinks linkGroups={linkGroups} socialmedia={socialmedia} />
    </Container>
    <Container>
      <Klarna className="klarna" />
    </Container>
  </FooterContainer>
);

const Footer = () => {
  const {
    apolloConfig: { shopid },
    routes: { integrityPage, inspiration, brands },
    siteConfig: {
      footerPageId,
      inspirationCategoryId,
      brandsPageId,
      socialmedia
    }
  } = useShopConfig();

  const id = footerPageId;
  const inspoID = inspirationCategoryId;
  const brandsID = brandsPageId;

  const { loading, data } = useQuery(footerQuery, {
    variables: { id, inspoID, brandsID }
  });

  if (loading) return <LoadingPage />;
  if (!data) return null;

  // Fetching linkgroups from footer content. Wrapping it into try-catch
  // in case there is an error in how it's formatted. Therefore it won't
  // break the entire site.
  let linkGroups = [];
  try {
    linkGroups = JSON.parse(striptags(data.page.content)).linkGroups;
  } catch (e) {}

  return (
    <Fragment>
      <ContentFooter
        data={data}
        inspirationPage={inspiration}
        inspirationCategoryId={inspirationCategoryId}
        brandsPage={brands}
      />

      <MainFooter linkGroups={linkGroups} socialmedia={socialmedia} />
      {shopid !== 'svenskahem' && (
        <CookieConsent cookiePageUrl={integrityPage.path} />
      )}
    </Fragment>
  );
};

export default Footer;
