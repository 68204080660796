import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import Price from '../../Price';
import { theme } from '../../Theme';
import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';
import { ButtonLink } from '../../ui/Button';
import cartQuery from '../../Cart/CartQuery.gql';

const Container = styled('aside')`
  width: 320px;
  ${theme.below.sm} {
    width: 100%;
  }
  background-color: ${theme.colors.white};
`;

const CheckoutButtonContinaer = styled('div')`
  width: 100%;
  text-align: center;
  padding: ${theme.space[2]};

  a {
    width: 100%;
  }
`;

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme.space[2]};
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
`;

const CloseButton = styled('button')`
  svg {
    width: 1em;
    height: 1em;
    use {
      fill: #000000;
    }
  }
`;

const Header = styled('h3')`
  ${theme.fontSizes.subHeading}
  margin-bottom: ${theme.space[1]};
`;

const ProductName = styled('p')`
  font-size: ${theme.fontSizes[0]};
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: ${theme.fontSizes[0]};
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  quantity,
  price,
  error
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  const title = product.canonicalCategory
    ? `${product.name} - ${product.canonicalCategory.name}`
    : product.name;

  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                sizes={80}
                aspect={'1:1'}
                alt={image.alt || product.name}
                quality={80}
                modifiedDate={image.modifiedDate}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : quantity === 1 ? (
              <Header>{t('Added to bag')}</Header>
            ) : (
              <Header>
                {t.rich('{quantity} produkter tillagda', { quantity })}
              </Header>
            )}
            <ProductName>{title}</ProductName>
            {!error && (
              <Price price={price.price} previousPrice={price.previousPrice} />
            )}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
          <CloseButton onClick={closeToast}>
            <Cross />
          </CloseButton>
        </Product>
        {cart && cart.externalCheckoutUrl && (
          <CheckoutButtonContinaer>
            <ChannelContext.Consumer>
              {({ selectedChannel }) => (
                <ButtonLink
                  href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                  onClick={() => {
                    track(trackCartCheckoutEvent({ cart }));
                  }}
                >
                  {t('To checkout')}
                </ButtonLink>
              )}
            </ChannelContext.Consumer>
          </CheckoutButtonContinaer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
