import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Link from '../utility/Link';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const BreadcrumbsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;

  ${theme.below.sm} {
    padding-top: 5px;
  }

  a,
  li {
    color: ${theme.colors.slategrey};
    ${theme.fontSizes.description}
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    ${theme.below.sm} {
      font-size: 0.7rem;
    }
  }

  li {
    flex-shrink: 0;
    list-style: none;

    ${theme.below.sm} {
      display: none;

      &:nth-last-child(1),
      &:nth-last-child(2) {
        display: block;
      }

      &:not(.first):nth-last-child(2)::before {
        content "... "
      }
    }
  }

  li:not(:last-of-type)::after {
    content: ">";
    padding: 0 0.33em;
  }

  a {
    ${theme.styles.linkEase}
    color: ${theme.colors.slategrey};
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const Breadcrumbs = ({ items = [], addHome = true, ...rest }) => {
  //Always add home
  if (addHome) {
    items.unshift({
      title: t('Home'),
      to: '/'
    });
  }

  return (
    <BreadcrumbsWrapper {...rest}>
      {items &&
        items.length > 0 &&
        items.map((item, i) => {
          return item.to ? (
            <li key={i} className={!i ? 'first' : ''}>
              <Link {...item}>{item.title}</Link>
            </li>
          ) : (
            <li key={i} {...item}>
              {item.title}
            </li>
          );
        })}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
