import { useEffect, useState } from 'react';

let passiveSupported = false;
// Scroll perf.
try {
  var opts = Object.defineProperty({}, 'passive', {
    get() {
      passiveSupported = true;
      return true;
    }
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {}

const useHasScrolled = ({ top }) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollCallback = () => setHasScrolled(window.scrollY > (top || 0));
  useEffect(() => {
    window.addEventListener(
      'scroll',
      scrollCallback,
      passiveSupported ? { passive: true } : false
    );

    return () =>
      window.removeEventListener(
        'scroll',
        scrollCallback,
        passiveSupported ? { passive: true } : false
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return hasScrolled;
};

export default useHasScrolled;
