import React from 'react';
import { styled } from 'linaria/react';
import Container from '../../Layout/Container';
import { EntryLarge, EntryContainer } from '../../ui/EntryCard';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledEntryContainer = styled(EntryContainer)`
  background: ${theme.colors.bggrey};
`;

const StyledEntryCard = styled(EntryLarge)`
  text-decoration: none;

  .content-large {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;

    a {
      text-decoration: none;
    }
  }

  .content-inner {
    margin: 0 auto;
    text-align: center;

    .topTitle,
    .title {
      color: ${theme.colors.white} !important;
    }

    .title + .text {
      margin-top: 30px;
    }

    .text {
      margin-bottom: 0px;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export const CoverImage = ({
  title,
  text,
  titleHtml,
  textHtml,
  imageData,
  linkData,
  imageOverlay
}) => {
  return (
    <Container marginTop>
      <StyledEntryContainer>
        <StyledEntryCard
          title={title}
          text={text}
          image={imageData}
          link={linkData}
          overlay={imageOverlay}
          titleHtml={titleHtml}
          textHtml={textHtml}
        />
      </StyledEntryContainer>
    </Container>
  );
};

const DefaultCoverImage = ({
  title,
  text,
  titleHtml,
  textHtml,
  linkAsButton,
  children,
  imageOverlay
}) => {
  const image = children.filter(item => item.key === 'image-0');
  const link = children.filter(item => item.key === 'link-1');
  let imageData, linkData;
  if (image) {
    imageData = {
      src: image[0].props['image'].value?.value,
      alt: image[0].props['imageAlt'].value?.value
    };
  }

  if (link) {
    linkData = {
      text: link[0].props['linkTitle'].value?.value,
      to: link[0].props['linkUrl'].value?.value,
      asButton: linkAsButton.value?.value === 'show button' ? true : false
    };
  }

  return (
    <CoverImage
      title={title?.value?.value}
      text={text?.value?.value}
      titleHtml={titleHtml?.value?.value}
      textHtml={textHtml?.value?.value}
      children={children}
      imageData={imageData}
      linkData={linkData}
      imageOverlay={imageOverlay?.value?.value || false}
    ></CoverImage>
  );
};

export default DefaultCoverImage;
