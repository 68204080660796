import React, { Fragment, useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';

import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { useProductList } from '@jetshop/core/hooks/ProductList';

import USPBar from '../../USPBar';
import CartButton from '../../../../Cart/CartButton';
import { Logo } from '../../Logo';
import SearchBox from '../../SearchBox';

import { ReactComponent as HeartIcon } from '../../../../../svg/heart.svg';

import { theme } from '../../../../Theme';
import { HeartAnimations } from '../HeartAnimations';
import useHasScrolled from '../../../../utility/hooks/useHasScrolled';
import { useIntl } from '@jetshop/intl';
import { debounce } from '../../../../utility/functions';

const Header = styled('header')`
  position: sticky;
  z-index: 31;
  top: 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  align-items: center;
  width: 100%;
  color: var(--text-color, ${theme.colors.white});
  background-color: transparent;
  font-family: ${theme.fonts.heading};
  transition: top 0.4s;

  &.has-scrolled {
    top: -30px;
  }

  &:hover,
  &.has-scrolled,
  &.is-active {
    --text-color: #d7d7d7 !important;

    color: ${theme.colors.text};
    background-color: #f8f8f8;
    input {
      color: ${theme.colors.mediumdarkgrey};
    }
    a {
      color: ${theme.colors.text};
    }
    svg {
      color: ${theme.colors.text};
    }
  }

  &.is-active {
    background-color: ${theme.colors.white};
    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(57, 67, 72, 0.1);
    }
  }

  .navigation {
    display: grid;
    grid-template-columns: auto auto;
  }

  nav a {
    font-size: 1.225rem;
    text-decoration: none;
    color: inherit;
  }

  nav > ul > li > a {
    white-space: nowrap;
  }

  svg {
    display: block;
    height: 18px;
    color: inherit;
  }
`;

const NavButtons = styled('ul')`
  font-family: ${theme.fonts.primary};
  display: flex;
  align-items: center;
  color: ${theme.colors.black};

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 12px;
    border-radius: 20px;
    background-color: ${theme.colors.grey};
    :hover {
      background-color: #d0d0d0;
    }
  }

  li:first-child {
    margin: 0;
  }

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    width: 100%;
    height: 100%;
    color: ${theme.colors.text};
  }

  .favourties-count {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.35em;
    right: -0.7em;
    width: 1.7em;
    height: 1.7em;
    border-radius: 50%;
    font-size: 0.7rem;
    line-height: 0;
    color: ${theme.colors.white};
    background-color: ${theme.colors.text};

    &.visible {
      display: flex;
    }
  }
`;

const Search = styled(SearchBox)`
  display: flex;
  align-items: center;
  padding: 0 17px;
  background-color: #e0e0e0;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  font-size: 1.125rem;
  border-radius: 100px;
  input {
    ::placeholder {
      color: ${theme.colors.textlight};
    }
    :focus {
      color: ${theme.colors.text};
    }
  }
`;

const MainNavigation = styled('nav')`
  display: flex;
  justify-content: flex-start;
  padding: 0 0 0 32px;

  > ul > li:first-child > *:first-child {
    margin: 10px 0;
  }

  > ul {
    display: flex;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
    li.search-item {
      flex-grow: 1;
      max-width: 320px;
    }
    li.nav-link {
      > a {
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
      a {
        position: relative;
        overflow: hidden;
        text-decoration: none;

        ::before {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          background-color: ${theme.colors.text};
          height: 1px;
          bottom: 0;
          transform-origin: 100% 50%;
          transform: scaleX(0);
          transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
        }

        :hover::before {
          transform-origin: 0% 50%;
          transform: scaleX(1);
        }
      }
      &.has-children {
        > a {
          text-decoration: none;
        }
      }
      &.is-active {
        > a {
          ::before {
            content: '';
            transform-origin: 0% 50%;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  > ul > li + li,
  > ul + ul {
    margin-left: 1.375rem;
  }

  > ul > li:last-child {
    margin-right: 1.375rem;
  }

  .child {
    display: none;
  }
`;

const SecondaryNavigation = styled('nav')`
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 0 0;
  letter-spacing: 0.5px;

  a:hover {
    color: ${theme.colors.textlight};
  }

  > ul {
    text-transform: uppercase;
    display: flex;

    li {
      display: flex;
      align-items: center;
    }
  }

  > ul > li + li,
  > ul + ul {
    margin-left: 1.375rem;
  }

  svg {
    color: ${theme.colors.text};
  }
`;

const SubNavigation = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  background: ${theme.colors.white};
  width: 100%;
  pointer-events: none;
  transition: opacity 0.3s;

  &.delay {
    transition-delay: 0.1s;
  }

  &.active {
    opacity: 1;
    z-index: 32;
    pointer-events: initial;
    transition: opacity 0.1s;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 25px;
    background-image: linear-gradient(0deg, white 35%, transparent);
    pointer-events: none;
  }

  .container {
    position: relative;
  }

  .scrollable {
    height: 100%;
    overflow-y: auto;
    max-height: 600px;
  }

  .standard-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .masonry-grid {
    column-count: auto;
    column-gap: 5px;
    column-width: 140px;
    column-fill: balance;
  }

  .content {
    width: 100%;
    padding: 28px 32px 20px;
    font-family: ${theme.fonts.primary};

    a {
      ::before {
        content: none !important;
      }
      :hover {
        text-decoration: underline !important;
      }
    }

    > ul {
      min-width: 140px;
      margin-right: 2rem;
      margin-bottom: 1rem;
      &.break-inside {
        > li {
          display: block;
          > ul {
            > li {
              break-before: avoid;
              &:nth-child(9n - 1) {
                break-before: initial;
              }
            }
          }
        }
      }
      > li {
        display: inline-block;
        width: 100%;
        > ul {
          margin: 0.6875rem 0;
        }
        li {
          margin: 0 0 0.6875rem;
          a {
            display: block;
          }
        }
      }
    }

    ul a {
      font-size: 0.875rem;
      color: ${theme.colors.text};
      text-decoration: none;
    }

    > ul > li > a {
      font-family: ${theme.fonts.heading};
      letter-spacing: 1px;
      font-size: 1.125rem;
      color: ${theme.colors.text};
    }
  }
`;

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 30;
  animation: fadeIn 0.2s;
  @keyframes fadeIn {
    0%,
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SubNavigationSection = ({
  className,
  onEnter,
  onLeave,
  onClear,
  children,
  active,
  ...rest
}) => {
  if (!(children?.length > 0)) return null;
  const subsections = children?.length;
  const clearHandler = e => {
    onClear();
    onLeave();
  };
  return (
    <SubNavigation
      className={cx(className, active && 'active')}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div
        className="container"
        onKeyDown={e => {
          if (['Escape'].includes(e.nativeEvent.code)) {
            clearHandler();
          }
        }}
      >
        <div className="scrollable">
          <div className="content masonry-grid">
            {children.map(({ key, label, link, children }) => {
              if (!label) return null;
              return (
                <ul
                  key={key}
                  className={cx(
                    subsections < 3 && children.length > 9 && 'break-inside'
                  )}
                >
                  <li className="heading">
                    <Link
                      key={key}
                      to={link}
                      tabIndex={active ? undefined : -1}
                      onClick={clearHandler}
                    >
                      {label}
                    </Link>

                    {children?.length > 0 && (
                      <ul>
                        {children.map(({ key, link, label }) => {
                          return (
                            <li key={key}>
                              <Link
                                key={key}
                                to={link}
                                tabIndex={active ? undefined : -1}
                                onClick={clearHandler}
                              >
                                {label}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </SubNavigation>
  );
};

const useMegaMenu = () => {
  const [activeKey, setActiveKey] = useState(undefined);

  const debouncer = debounce((key, callback) => callback.call(null, key));
  const trigger = callback => key => {
    if (!activeKey) {
      callback.call(null, key);
      return;
    }
    debouncer(key, callback);
  };

  const enter = key => setActiveKey(key);
  const leave = () => setActiveKey(undefined);
  const enterDebounce = key => trigger(enter)(key);
  const leaveDebounce = () => trigger(leave)();
  const toggle = key => (activeKey === key ? leave : enter)(key);

  // touchevents
  const touchEnter = (e, key) => {
    if (!activeKey || (activeKey && activeKey !== key)) {
      e.preventDefault();
      enter(key);
    } else {
      leave();
    }
  };

  return {
    activeKey,
    enterDebounce,
    leaveDebounce,
    enter,
    leave,
    toggle,
    touchEnter
  };
};

export default function DesktopMenu({ usps, navigation, textColor }) {
  // use the hook for translations so we can use them on attributes
  const t = useIntl();
  // the page has scrolled from the top, in px.
  const hasScrolled = useHasScrolled({ top: 0 });
  const { count } = useProductList();
  const { routes } = useShopConfig();

  const {
    activeKey,
    enterDebounce,
    leaveDebounce,
    toggle,
    leave,
    touchEnter
  } = useMegaMenu();

  return (
    <Fragment>
      <Header
        style={{
          '--text-color': textColor || 'inherit'
        }}
        className={cx(hasScrolled && 'has-scrolled', 'is-active')}
      >
        <USPBar usps={usps} />
        <div className="navigation">
          <MainNavigation className="main-navigation">
            <ul>
              <li>
                <Logo onClick={leave} />
              </li>
              {navigation?.length > 0 &&
                navigation.map(item => {
                  const { key, link, label, children } = item;
                  const hasChildren = children?.length > 0;

                  return (
                    <li
                      key={key}
                      className={cx(
                        'nav-link',
                        hasChildren && 'has-children',
                        activeKey === key && 'is-active'
                      )}
                    >
                      {hasChildren ? (
                        <Link
                          to={link}
                          aria-expanded={key === activeKey}
                          onClick={leave}
                          onKeyDown={e => {
                            if (
                              e.nativeEvent.repeat ||
                              ['Space', 'Enter', 'Escape'].includes(
                                e.nativeEvent.code
                              )
                            ) {
                              if (e.nativeEvent.code === 'Escape') {
                                leave();
                              } else {
                                if (activeKey === key) {
                                  return;
                                } else {
                                  e.preventDefault();
                                  toggle(key);
                                }
                              }
                            }
                          }}
                          onMouseEnter={() => enterDebounce(key)}
                          onMouseLeave={() => leaveDebounce()}
                          onTouchEnd={e => touchEnter(e, key)}
                        >
                          {label}
                        </Link>
                      ) : (
                        <Link to={link}>{label}</Link>
                      )}
                      {hasChildren && (
                        <SubNavigationSection
                          {...item}
                          active={activeKey === key}
                          onEnter={() => enterDebounce(key)}
                          onLeave={leaveDebounce}
                          onClear={leave}
                          className={activeKey && activeKey !== key && 'delay'}
                        ></SubNavigationSection>
                      )}
                    </li>
                  );
                })}
              <li className="search-item">
                <Search className={hasScrolled && 'has-scrolled'} />
              </li>
            </ul>
          </MainNavigation>

          <SecondaryNavigation>
            <ul className="static-links">
              <li>
                <Link to={routes.inspiration.path}>Inspiration</Link>
              </li>
              <li>
                <Link to={routes.stores.path}>Butiker</Link>
              </li>
            </ul>
            <NavButtons>
              <li>
                <HeartAnimations />
                <span className={cx('favourties-count', count && 'visible')}>
                  {count}
                </span>
                <Link to={routes.favourites.path} aria-label={t('Favourites')}>
                  <HeartIcon />
                </Link>
              </li>
              <li>
                <CartButton />
              </li>
            </NavButtons>
          </SecondaryNavigation>
        </div>
      </Header>
      {activeKey && <Overlay onClick={leave} />}
    </Fragment>
  );
}
