const breakpoints = {
  xs: '33rem',
  sm: '48rem',
  md: '62rem',
  lg: '75rem',
  xl: '87rem'
};

const above = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const fonts = {
  primary: '"Open Sans", sans-serif',
  heading:
    '"DINEngschriftLT-Alternate", "Avenir Next Condensed", Arial, sans-serif'
};

//FontSizes
const supermega = `
  font-size: 1.875rem;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: ${fonts.heading};

  ${above.md} {
    font-size: 3rem;
    line-height: 1.3;
    letter-spacing: 1.2px;
  }

  ${above.xl} {
    font-size: 4.5rem;
    line-height: 1.3;
    letter-spacing: 1.4px;
  }
`;

const mega = `
  font-size: 1.875rem;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: ${fonts.heading};

  ${above.md} {
    font-size: 3rem;
    line-height: 1.3;
    letter-spacing: 1.2px;
  }
`;

const hero = `
  font-size: 1.5rem;
  line-height: 1.38;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: ${fonts.heading};

  ${above.md} {
    font-size: 1.875rem;
    line-height: 1.3;
    letter-spacing: 1px;
  }
`;

const heading = `
  font-size: 1.5rem;
  line-height: 1.38;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: ${fonts.heading};
`;

const subHeading = `
  font-size: 1.3rem;
  line-height: 1.39;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: ${fonts.heading};
`;

const regular = `
  font-size: 1.125rem;
  line-height: 1.39;
  letter-spacing: 0.26px;
`;

const description = `
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

const colors = {
  white: '#ffffff',
  black: '#57595b',
  secondaryblack: '#011019',
  grey: '#e4e4e4',
  gray2: '#4F4F4F',
  secondarygrey: '#414c52',
  lightgrey: '#f8f8f8',
  bggrey: '#d5d6d5',
  red: '#d60019',
  yellow: '#F2C94C',
  blue: '#006aff',
  green: '#21e496',
  text: '#394348',
  textlight: '#747B7F',
  textwhite: '#f5f0f0',
  textblack: '#373d3f',
  textgrey: '#858d9b',
  slategrey: '#414c52',
  background: '#ffffff',
  loadingBar: '#006aff',
  highlight: '#006aff',
  tablegrey: '#858d9b',
  mediumgrey: '#858d9b',
  mediumdarkgrey: '#808080',
  darkgrey: '#e4e4e4'
};

let easings = {
  easeOutSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
  easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)'
};

const myPagesIdProd = '5ad45283-fd8b-491b-8c57-fe2d508c31da';
const myPagesIdTest = 'c7935167-7b5e-4cd5-b49b-ac15bf1d7663';

easings.primary = easings.easeOutQuint;
easings.secondary = easings.easeInOutSine;

module.exports = {
  default: {
    colors,
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 600,
      bold: 700
    },
    breakpoints,
    fontSizes: {
      0: '0.874rem',
      1: '1.125rem',
      description: description,
      regular: regular,
      subHeading: subHeading,
      heading: heading,
      hero: hero,
      mega: mega,
      supermega: supermega
    },
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts,
    fallbackStyles: `
        font-family: system-ui;
        font-size: 1.125rem;
        letter-spacing: 0.0026em;
        font-weight: 400;
        visibility: visible;
    `,
    styles: {
      boxshadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
      linkEase: `
        position: relative;
        color: ${colors.black};
        text-decoration: none;
        transition: all 450ms ease;

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 1px;
            pointer-events: none;
            background: currentColor;
            transform: scale3d(0,1,1);
            transform-origin: 100% 50%;
            transition: background 450ms ease,
                        transform 450ms ${easings.primary};
            will-change: transform;
        }

        &:focus,
        &:hover {
            color: ${colors.black};

            &::before {
            background: currentColor;
            transform: scale3d(1,1,1);
            transform-origin: 0% 50%;
            }
        }
    `
    },
    easings,
    above,
    myPagesIdProd,
    myPagesIdTest
  }
};
