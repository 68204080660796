import React, { useState, useEffect } from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';

import t from '@jetshop/intl';

import { ReactComponent as ChevronSVG } from '../../../../../svg/Carrot.svg';
import { ReactComponent as CrossIcon } from '../../../../../svg/Cross.svg';

import { theme } from '../../../../Theme';
import { randomKey } from '../../../../utility/functions';

const MenuWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: ${theme.colors.text};

  .panel-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    transform: translateX(calc(var(--current) * -100%));
    transition: transform 0.4s;
  }

  .panel {
    position: relative;
    flex-shrink: 0;
    padding: 5rem 2rem 2rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    svg {
      width: 0.75rem;
      height: auto;
    }
  }
`;

const MenuHeader = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  z-index: 2;

  .back-button {
    pointer-events: none;
    opacity: 0;
  }

  &.deeper {
    box-shadow: 0 0 12px -3px rgba(0, 0, 0, 0.2);

    .back-button {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;

const StyledCloseButton = styled('button')`
  font-family: ${theme.fonts.heading};
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 20px;
  height: 20px;
  line-height: 0;
  overflow: hidden;
  transform: translateX(25%);

  span {
    display: none;
  }
  svg {
    width: 14px;
  }
`;

const CloseButton = ({ close }) => (
  <StyledCloseButton onClick={close}>
    <span>{t('Close')}</span>
    <CrossIcon />
  </StyledCloseButton>
);

const StyledBackButton = styled('button')`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.heading};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: none;
  transition: opacity 0.4s;
  color: ${theme.colors.text};

  svg {
    margin-right: 0.5rem;
    margin-bottom: 1px;
    transform: rotate(90deg);
  }
`;

const GoBack = ({ className, children, pop }) => {
  return (
    <StyledBackButton className={className} onClick={pop}>
      <ChevronSVG /> {children || t('Tillbaka')}
    </StyledBackButton>
  );
};

let then = 0;
const PanelsHandler = ({ root, cooldown = 200, drawer, reset }) => {
  // reset panels to the first one
  useEffect(() => {
    if (reset && !drawer.isOpen) {
      setTimeout(() => {
        pop(0);
      }, reset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawer.isOpen]);

  const [state, setState] = useState({
    current: 0,
    panels: []
  });

  const pop = curr => {
    if (!Number.isInteger(curr)) {
      curr = state.current > 0 ? state.current - 1 : 0;
    }
    setState(state => ({ ...state, current: curr }));
  };

  const push = (component, props) => {
    if (!component) return;
    const now = Date.now();
    if (now - then < cooldown) return;
    then = now;
    let currentSlice = state.panels;
    if (state.current < currentSlice.length - 1) {
      currentSlice = state.panels.slice(0, state.current + 1);
    }
    setState({
      current: currentSlice.length,
      panels: [
        ...currentSlice,
        {
          Panel: component,
          key: randomKey(),
          props,
          drawer
        }
      ]
    });
  };

  const { component, props } = root;
  useEffect(() => {
    push(component, props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MenuHeader className={cx(state.current > 0 && 'deeper')}>
        <GoBack className="back-button" pop={pop} />
        <CloseButton close={drawer.hideTarget} />
      </MenuHeader>
      <MenuWrapper style={{ '--current': state.current }}>
        <div className="panel-wrapper">
          {state.panels.map(({ Panel, key, props, drawer }, index) => {
            return (
              <Panel
                key={key}
                props={props}
                helpers={{
                  push,
                  pop,
                  current: state.current,
                  index
                }}
                drawer={drawer}
              />
            );
          })}
        </div>
      </MenuWrapper>
    </>
  );
};

export default PanelsHandler;
