import React, { forwardRef } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { cx } from 'linaria';

/*==============================================================================
  # Styles
==============================================================================*/

const BaseContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  &.marginTop {
    margin-top: 30px;
  }

  &.useBackground {
    background: ${theme.colors.bggrey};
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.useFullWidth {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  padding-left: 10px;
  padding-right: 10px;

  ${theme.above.sm} {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${theme.above.md} {
    padding-left: 30px;
    padding-right: 30px;

    &.useBackground {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  ${theme.above.lg} {
    padding-left: 60px;
    padding-right: 60px;

    &.useBackground {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  ${theme.above.xl} {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const BaseRow = styled('div')`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  flex-wrap: wrap;
`;

/*==============================================================================
  # Components
==============================================================================*/

const BaseCol = styled('div')`
  position: relative;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 1px;

  /* Base column width */
  &.col {
    flex: ${({ col }) => `0 0 ${100 / (12 / col)}%`};
    max-width: ${({ col }) => `${100 / (12 / col)}%`};
  }

  /* Responsive column width */
  /* Ex: md="6" for half width above.md */
  .xs {
    ${theme.above.xs} {
      flex: ${({ xs }) => `0 0 calc(100% / (12 / ${xs}))`};
      max-width: ${({ xs }) => `calc(100% / (12 / ${xs}))`};
    }
  }

  &.sm {
    ${theme.above.sm} {
      flex: ${({ sm }) => `0 0 calc(100% / (12 / ${sm}))`};
      max-width: ${({ sm }) => `calc(100% / (12 / ${sm}))`};
    }
  }

  &.md {
    ${theme.above.md} {
      flex: ${({ md }) => `0 0 calc(100% / (12 / ${md}))`};
      max-width: ${({ md }) => `calc(100% / (12 / ${md}))`};
    }
  }

  &.lg {
    ${theme.above.lg} {
      flex: ${({ lg }) => `0 0 calc(100% / (12 / ${lg}))`};
      max-width: ${({ lg }) => `calc(100% / (12 / ${lg}))`};
    }
  }

  &.xl {
    ${theme.above.xl} {
      flex: ${({ xl }) => `0 0 calc(100% / (12 / ${xl}))`};
      max-width: ${({ xl }) => `calc(100% / (12 / ${xl}))`};
    }
  }
`;

/*
 * Container
 */

const Container = forwardRef((props, ref) => {
  const {
    children,
    background,
    marginTop,
    fullWidth,
    className,
    ...rest
  } = props;

  return (
    <BaseContainer
      ref={ref}
      className={cx(
        ...(className?.split(' ') || []),
        background && 'useBackground',
        fullWidth && 'useFullWidth',
        marginTop && 'marginTop'
      )}
      {...rest}
    >
      {children}
    </BaseContainer>
  );
});
export default Container;

/*
 * Row
 */

export const Row = forwardRef((props, ref) => {
  const { children, ...rest } = props;
  return (
    <BaseRow ref={ref} {...rest}>
      {children}
    </BaseRow>
  );
});

/*
 * Col
 */

export const Col = forwardRef((props, ref) => {
  const { children, ...rest } = props;

  const extraClasses = ['col', 'xs', 'sm', 'md', 'lg', 'xl'];
  const classNames = [rest.className || ''];
  for (let x = 0; x < extraClasses.length; x++) {
    const attr = extraClasses[x];
    if (rest[attr]) classNames.push(attr);
  }

  return (
    <BaseCol ref={ref} {...rest} className={cx(...classNames)}>
      {children}
    </BaseCol>
  );
});
