import React, { Component } from 'react';
import { styled } from 'linaria/react';
import { ClearFilters } from './Filter';
import { SortDropdown } from './SortDropdown';
import { BooleanFilters } from './BooleanFilters';
import { ListFilters } from './ListFilters';
import { MultiListFilters } from './MultiListFilters';
import { RangeFilters } from './RangeFilters';
import { isDescendant } from '../../utility/functions';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  padding: 20px 10px 80px 10px;
`;

const FilterWrapper = styled('div')``;

const BoolWrapper = styled('div')``;

/*==============================================================================
  # Component
==============================================================================*/

class FilterDrawer extends Component {
  componentDidMount() {
    window.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (
      !isDescendant('filter-toggle-btn', e.target) &&
      !isDescendant('filter-drawer', e.target)
    ) {
      this.closeMenu();
    }
  };

  closeMenu = () => {
    if (typeof window !== 'undefined') {
      window.blur();
    }

    if (this.props.close) {
      this.props.close();
    }
  };

  render() {
    let { filters, sortOrders, ...rest } = this.props;

    //Display toppfilter first
    /*if (filters) {
      filters.forEach(function(item, i) {
        if (item.name === 'Produkttyp') {
          filters.splice(i, 1);
          filters.unshift(item);
        }
      });
    }*/

    return (
      <Wrapper id="filter-drawer" {...rest}>
        <FilterWrapper>
          <ClearFilters filters={filters} />

          <SortDropdown sortOrders={sortOrders} />

          <RangeFilters filters={filters} />

          <ListFilters filters={filters} />

          <MultiListFilters filters={filters} />
        </FilterWrapper>

        <BoolWrapper>
          <BooleanFilters filters={filters} />
        </BoolWrapper>
      </Wrapper>
    );
  }
}

export default FilterDrawer;
