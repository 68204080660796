import React from 'react';
import { styled } from 'linaria/react';
import Container from '../Layout/Container';
import { TextSection } from '../ui/Text';

/*==============================================================================
  # Styles
==============================================================================*/

const InnerContainer = styled(Container)`
  text-align: ${({ alignment }) => (alignment ? alignment : 'left')};
  align-items: ${({ alignment }) => {
    switch (alignment) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  }};
`;

/*==============================================================================
  # Component
==============================================================================*/

const Text = ({
  id,
  marginTop = true,
  alignment,
  wide = true,
  title,
  titleSize = 'lg',
  text,
  link,
  ...rest
}) => {
  if (link) {
    link.dark = true;
  }

  return (
    <Container id={id} fullWidth={true} marginTop={marginTop} {...rest}>
      <InnerContainer alignment={alignment}>
        <TextSection
          titleSize={titleSize}
          title={title}
          text={text}
          link={link}
          wide={wide}
        />
      </InnerContainer>
    </Container>
  );
};

export default Text;
