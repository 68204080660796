import React from 'react';
import { styled } from 'linaria/react';
import { Heading } from '../../ui/Headings';
import Image from '@jetshop/ui/Image/Image';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { Col } from '../../Layout/Container';
import { theme } from '../../Theme';

const Item = styled(Col)`
  padding: 0;
  a {
    text-decoration: none;
  }
`;

const ItemInner = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 83%;
  overflow: hidden;

  &:hover {
    [data-flight-image-container] {
      transform: scale(1.05);
      transition: all 5000ms ${theme.easings.primary} 100ms;

      &::after {
        background-color: ${theme.colors.grey};
        transition: all 5000ms ${theme.easings.primary} 100ms;
      }
    }
  }
`;

const ItemTitle = styled(Heading)`
  display: block;
  padding: 1rem 0 0;
  color: ${theme.colors.slategrey};
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
`;

const Background = styled(Image)`
  position: absolute !important;
  transform-origin: center center;
  transition: all 1000ms ${theme.easings.primary};

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.black};
    transition: all 1000ms ${theme.easings.primary};
    opacity: 0.25;
  }
`;

const CategoryCard = ({ cat }) => {
  return (
    <Item>
      <CategoryLink
        category={{ primaryRoute: { path: cat.link, alt: cat.label } }}
      >
        <ItemInner>
          {cat.images.length > 0 && (
            <Background
              src={cat.images[2].url}
              alt={cat.label}
              sizes={[450]}
              aspect="1:1"
              fillAvailableSpace={true}
            />
          )}
        </ItemInner>
        <ItemTitle>{cat.label}</ItemTitle>
      </CategoryLink>
    </Item>
  );
};

export default CategoryCard;
