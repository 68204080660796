import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { CoverImage } from '../ContentEditor/Components/CoverImage';
import CategoryCard from '../ContentEditor/Components/CategoryGridItem';
import Container from '../Layout/Container';

const Grid = styled(Container)`
  --columns: 3;
  display: grid;
  grid-gap: 5px;
  row-gap: 40px;
  grid-template-columns: repeat(var(--columns), 1fr);
  margin-top: 40px;

  ${theme.below.md} {
    --columns: 2;
    row-gap: 20px;
    margin-top: 20px;
  }
`;

const CategoryCustomOverview = ({ label, link, images, children, ...rest }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const seo = {
    title: `${label} - Svenska Hem`,
    description: `Allt inom ${label}`,
    image: images?.src
  };
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta name="description" content={seo.description} />
        <meta property="og:type" content="website" />
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta property="og:url" content={selectedChannel.url} />
      </Helmet>
      <CoverImage title={label} imageData={images} />
      <Grid>
        {children.map(cat => {
          return <CategoryCard key={cat.key} cat={cat}></CategoryCard>;
        })}
      </Grid>
    </>
  );
};

export default CategoryCustomOverview;
