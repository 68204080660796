import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Container from '../Layout/Container';
import NewsletterField from '../Cart/Newsletter/NewsletterField';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Description = styled('p')`
  margin-top: 10px;
  font-style: italic;
  color: ${theme.colors.slategrey};
  ${theme.fontSizes.description}
`;

const AnchorOffset = styled('div')`
  margin-top: -100px;
  padding-top: 100px;
`;

/*==============================================================================
  # Component
==============================================================================*/

const Newsletter = ({ id, marginTop = true, title, text, confirmText }) => {
  title = title !== undefined ? title : t('Prenumerera på vårt nyhetsbrev');

  return (
    <Container fullWidth={true} marginTop={marginTop}>
      <AnchorOffset id={id}>
        <Container>
          <div>
            {title && (
              <label htmlFor={`newsletter-${id}`} className="heading-size">
                {title}
              </label>
            )}
          </div>

          <NewsletterField
            inputId={`newsletter-${id}`}
            large={false}
            confirmText={confirmText}
          />

          {text && <Description>{text}</Description>}
        </Container>
      </AnchorOffset>
    </Container>
  );
};

export default Newsletter;
