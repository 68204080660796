import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const CloseBtn = styled('span')`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${theme.colors.grey};
  cursor: pointer;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 2px;
    transform-origin: center center;
    background-color: ${theme.colors.black};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const CloseButton = ({ onClick, className }) => (
  <CloseBtn onClick={onClick} className={className} />
);

export default CloseButton;
