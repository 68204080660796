import React from 'react';
import { styled } from 'linaria/react';
import Container from '../Layout/Container';
import EntryCard, { EntryLarge, EntryContainer } from '../ui/EntryCard';
import { Hero } from '../ui/Headings';
import BorderHeading from '../ui/BorderHeading';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledEntryContainer = styled(EntryContainer)``;

const TopEntryContainer = styled(StyledEntryContainer)``;

const BottomEntryContainer = styled(StyledEntryContainer)`
  margin-bottom: -30px;

  ${theme.below.md} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
  }
`;

const ArticleCard = styled(EntryCard)`
  :not(:last-child) {
    margin-right: 5px;
  }
  ${theme.below.xs} {
    width: 90vw;
    max-width: 90vw;
    min-width: 90vw;
  }

  ${theme.only.xs} {
    width: 45vw;
    max-width: 45vw;
    min-width: 45vw;
  }

  ${theme.only.sm} {
    width: 33.33%;
    max-width: 33.33%;
    min-width: 33.33%;
  }

  .content {
    ${theme.below.md} {
      padding: 10px;
    }
  }

  h2 {
    ${theme.below.md} {
      margin-bottom: 10px;
    }
  }
`;

const ArticleCardLarge = styled(EntryLarge)`
  margin-bottom: 5px;
  .content-large {
    justify-content: flex-end;
    padding: 15px;
  }

  .content-inner {
    margin: 0px;
    text-align: left;
  }

  .title {
    ${theme.above.md} {
      font-size: 3rem;
      line-height: 1.25;
      letter-spacing: 0.5px;
    }
  }

  .topTitle,
  .title {
    color: ${theme.colors.white} !important;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const Articles = ({ id, marginTop, background, title, entries }) => {
  return (
    <Container
      id={id}
      fullWidth={true}
      background={background}
      marginTop={marginTop}
    >
      <Container>
        {title && (
          <BorderHeading align="center">
            <Hero>{title}</Hero>
          </BorderHeading>
        )}

        {entries && entries.length > 0 && (
          <TopEntryContainer>
            <ArticleCardLarge
              topTitle={entries[0].topTitle}
              title={entries[0].title}
              image={entries[0].image}
              link={entries[0].link}
            />
          </TopEntryContainer>
        )}

        {entries && entries.length > 1 && (
          <BottomEntryContainer>
            {entries
              .filter((entry, i) => i !== 0)
              .map((entry, i) => {
                let { link } = entry;
                link.dark = true;

                return (
                  <ArticleCard
                    key={i}
                    topTitle={entry.topTitle}
                    title={entry.title}
                    image={entry.image}
                    link={link}
                    visibleButton={false}
                  />
                );
              })}
          </BottomEntryContainer>
        )}
      </Container>
    </Container>
  );
};

export default Articles;
