import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ProductCard } from './ProductCard';
import { theme } from '../Theme';
import { Favourite } from '../ProductList/Favourite';

const Wrapper = styled('ul')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;

  ${theme.above.sm} {
    margin-right: -15px;
    margin-left: -15px;
  }

  .product-card {
    width: 50%;
    max-width: 50%;

    ${theme.above.md} {
      width: 33.333%;
      max-width: 33.33%;
    }
    ${theme.above.lg} {
      width: 25%;
      max-width: 25%;
    }
  }
`;

export function ProductGrid({
  products = [],
  listName,
  loading,
  className,
  ...rest
}) {
  const track = useTracker();

  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (products.length === 0) return null;

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            list={listName}
            {...rest}
          >
            <Favourite product={product} />
          </ProductCard>
        );
      })}
    </Wrapper>
  );
}

export default ProductGrid;
