import React from 'react';
import { styled } from 'linaria/react';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { ReactComponent as HeartSVG } from '../../svg/heart.svg';

const StyledFavourite = styled('div')`
  button {
    background: transparent;
    svg.active {
      path {
        fill: currentColor;
        fill-opacity: 1 !important;
      }
    }
  }
`;

export function Favourite({ product, variant, ...rest }) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <StyledFavourite className="favourite" {...rest}>
      <button aria-label="Favourite" onClick={toggleWithoutBubble}>
        <HeartSVG className={isInList ? 'active' : 'inactive'} />
      </button>
    </StyledFavourite>
  );
}
