import { css } from 'linaria';
import { theme } from './components/Theme';

export const globals = css`
  :global() {
    html,
    body {
      height: 100%;
      font-family: ${theme.fonts.primary};
    }
    html,
    body,
    #root {
      min-height: 100%;
    }
    #root {
      display: flex;
      flex-direction: column;
      overflow-anchor: none;
    }
    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
      background: none;
    }
    ol,
    ul,
    h4,
    h3,
    h2,
    h1 {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    p,
    dd {
      margin: 0;
      padding: 0;
    }
    input {
      -webkit-appearance: none;
    }
    .ReactModal__Body--open {
      overflow: hidden;
    }
    .ReactModal__Overlay--after-open {
      overflow: scroll;
    }
    body {
      ${theme.fallbackStyles};
      -webkit-font-smoothing: antialiased;
    }
    ::-moz-selection {
      background: ${theme.colors.highlight};
      color: ${theme.colors.white};
    }

    ::selection {
      background: ${theme.colors.highlight};
      color: ${theme.colors.white};
    }
    /*
   * apply a natural box layout model to all elements, but allowing components
   * to change */
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
    /*
   * font-size
   */
    .super-mega-size {
      ${theme.fontSizes.supermega}
    }
    .mega-size {
      ${theme.fontSizes.mega}
    }
    h1,
    .hero-size {
      ${theme.fontSizes.hero}
    }
    h2,
    .heading-size {
      ${theme.fontSizes.heading}
    }
    h3,
    h4,
    .sub-heading-size {
      ${theme.fontSizes.subHeading}
    }
    h5,
    h6 {
      font-family: ${theme.fonts.heading};
    }
    h5,
    h6,
    .regular-size {
      ${theme.fontSizes.regular}
    }
    .small-heading-size {
      font-family: ${theme.fonts.heading};
    }
    .small-heading-size,
    .description-size {
      ${theme.fontSizes.description}
    }
    img {
      font-size: 0;
    }
    [data-flight-image-loaded='true'],
    [data-flight-image-loaded='false'] {
      font-size: 0;
      > div {
        background: ${theme.colors.white} !important;

        &::before {
          content: 'Bild saknas';
          color: ${theme.colors.textgrey};
          margin: auto;
          ${theme.fontSizes.regular}
        }
      }
    }
    svg {
      color: ${theme.colors.text};
    }
  }
`;
