import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import {
  DropdownMenuButton as Button,
  DropdownMenuItem,
  DropdownMenuItems as Items
} from '@jetshop/ui/DropdownMenu';
import { FilterDropdownStyles } from './FilterDropdownStyles';
import { getObjectFromArrayByKeyValue } from '../../utility/functions';
import { theme } from '../../Theme';
import { pubDocsSrc } from '../../utility/functions';

/*==============================================================================
  # Styles
==============================================================================*/

export const Label = styled('label')`
  display: inline-block;
  margin-bottom: 5px;
  color: ${theme.colors.slategrey};
  ${theme.fontSizes.regular}
  font-family: ${theme.fonts.heading};
  text-transform: uppercase;
`;

/*==============================================================================
  # Component
==============================================================================*/

export function ListFilters({ filters }) {
  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter'
  );

  if (listFilters.length === 0) return null;

  return listFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <ListFilter filter={filter} />
    </ErrorBoundary>
  ));
}

function ListFilter({ filter }) {
  const { apply, clear } = useListFilter({ filter });
  const onSelect = value => () => apply({ value });

  let activeItems = [];
  if (filter && filter.items) {
    activeItems = getObjectFromArrayByKeyValue('isActive', true, filter.items);
    activeItems = activeItems.map(item => item.text);
  }
  let title = filter.hasActiveItems ? activeItems.join(', ') : 'Alla';

  return (
    <FilterDropdownStyles>
      <Label>{filter.name}</Label>

      <Button title={title}>
        <p>{title}</p>
        <span className="marker" />
      </Button>

      <Items as="ul">
        {filter.items.map(item => {
          let iconSrc = null;

          if (
            ['Material', 'Färg', 'Klädsel', 'Tyg'].indexOf(filter.name) >= 0
          ) {
            if (item.text) {
              iconSrc = pubDocsSrc('filterikoner', item.text, 'jpg');
            }
          }

          return (
            <DropdownMenuItem
              key={item.id}
              onSelect={item.resultCount > 0 && onSelect(item.value)}
              style={
                item.resultCount === 0
                  ? {
                      opacity: 0.4,
                      cursor: 'not-allowed'
                    }
                  : {}
              }
            >
              {iconSrc && (
                <span className="icon">
                  <Image
                    size={24}
                    aspect="1:1"
                    src={iconSrc}
                    alt={item.text}
                    modifiedDate={item.modifiedDate}
                  />
                </span>
              )}
              <span>{item.text}</span>
              <span
                className={item.isActive ? 'checkbox choosen' : 'checkbox'}
              />
            </DropdownMenuItem>
          );
        })}

        <Fragment>
          {filter.hasActiveItems && (
            <DropdownMenuItem
              onSelect={({ setIsOpen }) => {
                setIsOpen(false);
                clear();
              }}
            >
              {t('Clear filter')}
            </DropdownMenuItem>
          )}
        </Fragment>
      </Items>
    </FilterDropdownStyles>
  );
}
