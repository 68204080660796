import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const BorderWrapper = styled('div')`
  color: ${theme.colors.slategrey};
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.slategrey};

  &.text-left {
    text-align: left;
  }

  &.text-center {
    text-align: center;
  }

  &.text-right {
    text-align: right;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const BorderHeading = ({ children, align, className }) => {
  let classes = className;
  if (align) classes += ' text-' + align;

  return <BorderWrapper className={classes}>{children}</BorderWrapper>;
};

export default BorderHeading;
