import React, { Fragment } from 'react';
import { css, cx } from 'linaria';
import { toast } from 'react-toastify';
import t from '@jetshop/intl';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import uniqueId from '@jetshop/core/helpers/uniqueId';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { Header, HeaderContainer } from '../SearchPage/SearchResults';
import Container from '../Layout/Container';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Mega } from '../ui/Headings';
import Breadcrumbs from '../ui/Breadcrumbs';
import { Product } from './Product';
import { ReactComponent as Cart } from '../../svg/shopping-bag.svg';
import cartQuery from '../Cart/CartQuery.gql';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';

/*==============================================================================
  # Styles
==============================================================================*/

const container = css`
  &.loading {
    opacity: 0.6;
  }

  .title {
    text-align: center;
    margin-bottom: 30px;
  }

  .intro-text {
    color: ${theme.colors.textgrey};
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-right: -${theme.space[1]};
    margin-left: -${theme.space[1]};
    width: 100%;

    > * {
      line-height: 19px;
      min-width: 0;
      margin: ${theme.space[1]};
      width: calc(50% - ${theme.space[2]});

      ${theme.above.md} {
        width: calc(33.333% - ${theme.space[2]});
      }
      ${theme.above.lg} {
        width: calc(25% - ${theme.space[2]});
      }
    }
  }

  .product-card-detail,
  .product-card,
  .product-card a {
    background: white;
  }

  li:not([data-valid='valid']) .product-card {
    background: rgba(255, 255, 255, 0.2);
    a {
      opacity: 0.5;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .select-variant {
    margin: 0;
    padding: 1em;
    position: relative;
    z-index: 999;

    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }
      button,
      ul,
      li {
        width: 100%;
      }
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .add-clear {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -10px;
    margin-bottom: 30px;

    button {
      margin: 10px;
    }
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export function Favourites() {
  const { products, loading } = useProductListItems();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        toast(
          <AddAllSuccessToast>
            <Cart />
            {t(
              '{productCount, plural, =1 {# produkt tillagd i kundvagnen.} other {# produkter tillagda i kundvagnen.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>,
          {
            toastId: uniqueId(),
            autoClose: 2000
          }
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  //Breadcrumbs
  let breadcrumbs = [
    {
      title: 'Sparade produkter'
    }
  ];

  if (noProductsInList) {
    return (
      <Header>
        <Container marginTop={false}>
          <Breadcrumbs items={breadcrumbs} />
        </Container>

        <HeaderContainer className={cx(container, loading && 'loading')}>
          <Mega className="title" size="h1">
            {t('Sparade produkter')}
          </Mega>
          <p className="intro-text">{t('Du har inga sparade produkter.')}</p>
        </HeaderContainer>
      </Header>
    );
  }

  if (loadingInitialRender) {
    return (
      <Header>
        <Container marginTop={false}>
          <Breadcrumbs items={breadcrumbs} />
        </Container>

        <HeaderContainer className={cx(container, loading && 'loading')}>
          <Mega className="title" size="h1">
            {t('Sparade produkter')}
          </Mega>
          <p className="intro-text">{t('Laddar dina sparade produkter…')}</p>
        </HeaderContainer>
      </Header>
    );
  }

  return (
    <Fragment>
      <Header>
        <Container marginTop={false}>
          <Breadcrumbs items={breadcrumbs} />
        </Container>

        <HeaderContainer>
          <Mega className="title" size="h1">
            {t('Sparade produkter')}
          </Mega>
        </HeaderContainer>
      </Header>

      <Container className={cx(container, loading && 'loading')}>
        <ul className="product-grid">
          {products.map((product, index) => {
            return (
              <li
                key={product.variant?.articleNumber || product.articleNumber}
                data-valid={product.validation.status}
              >
                <Product product={product} />
              </li>
            );
          })}
        </ul>
      </Container>

      <Container
        className={cx(container, loading && 'loading')}
        marginTop={true}
      >
        <div className="add-clear">
          <Button
            onClick={addToCart}
            loading={mutationLoading}
            className="add-all"
            aria-label="Add all"
            disabled={validItemCount === 0}
          >
            {validItemCount > 0
              ? mutationLoading
                ? t('Vänta lite…')
                : t(
                    '{productCount, plural, =1 {Lägg till # produkt i kundvagnen} other {Lägg till # produkter i kundvagnen}}',
                    { productCount: validItemCount }
                  )
              : t('Inga giltiga föremål att lägga till i kundvagnen')}
          </Button>
          <ClearList>{t('Rensa sparade produkter')}</ClearList>
        </div>
      </Container>
    </Fragment>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
  const { clear } = useProductList();
  return (
    <Button aria-label="Clear list" onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}
