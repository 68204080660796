import React from 'react';
import { styled } from 'linaria/react';
import Container, { Row, Col } from '../Layout/Container';
import BrandLogo from '../ui/BrandLogo';
import { ButtonLink } from '../ui/Button';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const BrandCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px 0px;
  padding: 0px;

  ${theme.above.xs} {
    padding: 0px 5px;
  }
`;

const StyledBrandLogo = styled(BrandLogo)`
  margin: 10px;

  ${theme.below.md} {
    width: 100px;
  }

  div {
    padding-bottom: 60% !important;
  }
`;

const ButtonContainer = styled('div')`
  padding: 0px 15px;
  margin-top: 30px;
`;

/*==============================================================================
  # Component
==============================================================================*/

const Brands = ({ id, marginTop = true, background = false, brands, link }) => {
  return (
    <Container
      id={id}
      fullWidth={true}
      background={background}
      marginTop={marginTop}
    >
      <Container>
        <Row justify="center">
          <BrandCol col={12} md={10}>
            {brands &&
              brands.length > 0 &&
              brands.map((brand, i) => (
                <StyledBrandLogo key={i} width={140} {...brand} />
              ))}
          </BrandCol>
        </Row>

        {link && (
          <Row justify="center">
            <ButtonContainer>
              <ButtonLink {...link}>{link.title}</ButtonLink>
            </ButtonContainer>
          </Row>
        )}
      </Container>
    </Container>
  );
};

export default Brands;
