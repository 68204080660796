import React from 'react';
import { styled } from 'linaria/react';
import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import { LabelGroup, Label } from './CartFlyout';

/*==============================================================================
  # Styles
==============================================================================*/

const FreeDelivery = styled('span')`
  ${theme.fontSizes.regular}

  ${theme.below.md} {
    ${theme.fontSizes.description}
  }
`;

const SpendMore = styled('span')`
  ${theme.fontSizes.description}

  div {
    display: inline;
    font-weight: 700;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const FreeShipping = ({ cartTotal, ...rest }) => {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  if (!freeShippingConfigured) return null;

  if (!hasMetLimit)
    return (
      <LabelGroup key="until-limit">
        <Label>{t('Delivery')}</Label>
        <SpendMore>
          {t.rich(`Spend {price} more to qualify for free shipping!`, {
            price: <Price key="price" price={untilLimit} />
          })}
        </SpendMore>
      </LabelGroup>
    );

  return (
    <LabelGroup {...rest}>
      <Label>{t('Delivery')}</Label>
      <FreeDelivery className="bold">{t('Free for your order')}</FreeDelivery>
    </LabelGroup>
  );
};

export default FreeShipping;
