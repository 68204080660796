import { useEffect, useRef, useState } from 'react';

export const useIntervalTimer = (delay, callback) => {
  const [iteration, setIteration] = useState(0);
  const timeoutRef = useRef(null);

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const createTimer = () => {
    timeoutRef.current = setTimeout(() => {
      setIteration(prevIteration => prevIteration + 1);
      if (typeof callback === 'function') {
        callback.call(null, { iteration });
      }
    }, delay);
  };

  useEffect(() => {
    clearTimer();
    createTimer();
    return clearTimer;
  }, [iteration]); // eslint-disable-line react-hooks/exhaustive-deps

  return { iteration, createTimer, clearTimer };
};
