const PrepareInspirationCards = (rawCategories, inspirationCategoryId) => {
  let articleCategories = {};
  let articles = [];

  //Loop through all inspiration categories (articles)
  if (rawCategories) {
    for (let i = 0; i < rawCategories.length; i++) {
      let category = rawCategories[i];
      let { id, parent, name, mainHeader, images, primaryRoute } = category;

      //Update articles
      //Do not include lvl 1 categories, it's only level 3 categories that is used as articles
      if (id !== inspirationCategoryId) {
        //Lvl 2 categories is used to group articles togheter
        if (parent?.id === inspirationCategoryId) {
          articleCategories[id] = {
            id: id,
            title: mainHeader
          };

          //Lvl 3 categories is used as articles
        } else {
          articles.push({
            id: id,
            categoryID: parent.id,
            topTitle: parent.name,
            title: mainHeader,
            image: {
              src:
                images && images.length > 0
                  ? images[images.length - 1].url
                  : null,
              alt: name
            },
            link: {
              title: name,
              to: primaryRoute.path
            }
          });
        }
      }
    }

    //Reverse array to get latest first
    articles = articles.reverse();
  }

  return {
    articleCategories: articleCategories,
    articles: articles
  };
};

export default PrepareInspirationCards;
