import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import { PaginationBehaviour } from '@jetshop/ui/Pagination';
import ProductGrid from '../CategoryPage/ProductGrid';
import Pagination from './Pagination';
import SearchMeta from './SearchMeta';
import Filter from '../CategoryPage/Filters';
import Breadcrumbs from '../ui/Breadcrumbs';
import Container from '../Layout/Container';
import { Mega } from '../ui/Headings';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

export const Header = styled('div')`
  padding-bottom: 30px;
  text-align: center;

  ${theme.above.md} {
    padding-bottom: 60px;
  }
`;

export const HeaderContainer = styled(Container)`
  padding-top: 30px;

  ${theme.above.md} {
    padding-top: 60px;
  }

  ${theme.above.xl} {
    padding-top: 80px;
  }
`;

export const Matches = styled('div')`
  color: ${theme.colors.textgrey};
  padding: 15px 0;
`;

const FilterWrapper = styled('div')`
  margin-bottom: 15px;
`;

/*==============================================================================
  # Component
==============================================================================*/

const SearchResults = ({ loading, breadcrumbs, ...props }) => {
  const { total, term, filters } = props;
  const searchResult = props?.result || [];

  return (
    <PaginationContext.Consumer>
      {({ currentPage, goToPage, perPage }) => (
        <Fragment>
          <Header>
            <Container marginTop={false}>
              <Breadcrumbs items={breadcrumbs} addHome={false} />
            </Container>

            <HeaderContainer>
              <Mega size="h1">Sökresultat{term ? ` för "${term}"` : null}</Mega>

              <Matches>
                {loading ? (
                  currentPage === 1 ? (
                    <div>
                      {t(`Loading search results for {term}…`, {
                        term
                      })}
                    </div>
                  ) : (
                    <div>{t(`Laddar sida…`)}</div>
                  )
                ) : (
                  <div>
                    {t(
                      '{total, plural, =1 {# item} other {# items}} matching for "{term}"',
                      {
                        total,
                        term
                      }
                    )}
                  </div>
                )}
              </Matches>
            </HeaderContainer>
          </Header>

          <Container>
            {filters && filters.length > 0 && (
              <FilterWrapper>
                <Filter filters={filters} />
              </FilterWrapper>
            )}

            <PaginationBehaviour
              currentPage={currentPage}
              goToPage={goToPage}
              perPage={perPage}
              total={total}
            >
              {paginationProps => (
                <Fragment>
                  <SearchMeta
                    total={total}
                    page={currentPage}
                    pages={paginationProps.totalPages}
                  />

                  <ProductGrid
                    listName="search"
                    products={
                      searchResult?.data?.search?.products?.result || []
                    }
                    loading={loading}
                  />

                  <Pagination totalResults={total} />
                </Fragment>
              )}
            </PaginationBehaviour>
          </Container>
        </Fragment>
      )}
    </PaginationContext.Consumer>
  );
};

export default SearchResults;
