import React, { Fragment } from 'react';
import t from '@jetshop/intl';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import { useMultiFilter } from '@jetshop/core/hooks/Filters/useMultiFilter';
import {
  DropdownMenuButton as Button,
  DropdownMenuItem,
  DropdownMenuItems as Items
} from '@jetshop/ui/DropdownMenu';
import { FilterDropdownStyles } from './FilterDropdownStyles';
import { Label } from './ListFilters';
import { getObjectFromArrayByKeyValue } from '../../utility/functions';

/*==============================================================================
  # Component
==============================================================================*/

export function MultiListFilters({ filters }) {
  const multiListFilters = filters.filter(
    filter => filter.__typename === 'MultiListFilter'
  );

  return multiListFilters.map(multiListFilter => (
    <ErrorBoundary component={PassThrough} key={multiListFilter.id}>
      {multiListFilter.lists.map((listFilter, index) => (
        <Fragment>
          <MultiListFilter
            filter={listFilter}
            multiListFilter={multiListFilter}
            index={index}
          />
        </Fragment>
      ))}
    </ErrorBoundary>
  ));
}

function MultiListFilter({ filter, multiListFilter, index }) {
  const { apply, clearList } = useMultiFilter({
    filter: multiListFilter
  });

  let activeItems = [];
  if (filter && filter.items) {
    activeItems = getObjectFromArrayByKeyValue('isActive', true, filter.items);
    activeItems = activeItems.map(item => item.text);
  }
  let title = filter.hasActiveItems ? activeItems.join(', ') : 'Alla';

  return (
    <FilterDropdownStyles>
      <Label>{filter.name}</Label>

      <Button title={title}>
        <p>{title}</p>
        <span className="marker" />
      </Button>

      <Items as="ul">
        {filter.items.map(item => (
          <DropdownMenuItem
            key={item.id}
            onSelect={({ setIsOpen }) => {
              apply({
                value: item.value,
                listIndex: index
              });
              setIsOpen(false);
            }}
          >
            <span>{item.text}</span>
            <span className={item.isActive ? 'checkbox choosen' : 'checkbox'} />
          </DropdownMenuItem>
        ))}

        <Fragment>
          {filter.hasActiveItems && (
            <DropdownMenuItem
              onSelect={({ setIsOpen }) => {
                setIsOpen(false);
                clearList({ listIndex: index });
              }}
            >
              {t('Clear filter')}
            </DropdownMenuItem>
          )}
        </Fragment>
      </Items>
    </FilterDropdownStyles>
  );
}
