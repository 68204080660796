import React, { useState, Fragment } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import ReactSlider from 'react-slider';
import { useRangeFilter } from '@jetshop/core/hooks/Filters/useRangeFilter';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import { Label } from './ListFilters';
import { numberWithSeparator } from '../../utility/functions';

import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  width: 100%;
  height: 77px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;

  ${theme.above.md} {
    margin-bottom: 30px;
  }
`;

export const RangeSliderContainer = styled('div')`
  width: 100%;
  z-index: 5;

  .slider {
    height: 1px;
  }

  .slider.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .bar {
    background: transparent;
    height: 24px;

    &:after {
      height: 1px;
      width: 100%;
      position: absolute;
      top: calc(50%);
      background: ${theme.colors.black};
      content: '';
    }
    &-1:after {
      /* The active bar section */
      background: ${theme.colors.black};
    }
  }

  .handle {
    height: 24px;
    width: 24px;
    background: ${theme.colors.lightgrey};
    border: 1px solid ${theme.colors.black};

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      background-color: ${theme.colors.black};
      transform: translate(-50%, -50%);
    }
  }
`;

const wrapper = css`
  .active-vals {
    display: flex;
    margin-bottom: 5px;
    font-weight: 700;
    justify-content: space-between;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export function RangeFilters({ filters }) {
  const rangeFilters = filters.filter(
    filter => filter.__typename === 'NumericRangeFilter'
  );

  if (rangeFilters.length === 0) return null;

  return rangeFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <RangeFilter filter={filter} />
    </ErrorBoundary>
  ));
}

function RangeFilter({ filter }) {
  return (
    <Wrapper>
      <Label>{filter.name}</Label>
      <RangeSlider filter={filter} />
    </Wrapper>
  );
}

export function RangeSlider({ filter }) {
  const { apply } = useRangeFilter({ filter });
  // Filter.value may be null (on first render). If it is, use the filter min/max as initial value
  const [initialMin, initialMax] = filter.value
    ? filter.value
    : [filter.min, filter.max];

  // // Make sure the selected min/max are never lower or higher than
  // // the available min/max respectively.
  const selectedVals = [
    Math.max(initialMin, filter.min),
    Math.min(initialMax, filter.max)
  ];
  // draftVal will be used to track the values from the slider
  const [draftVal, setDraftVal] = useState({
    min: selectedVals[0],
    max: selectedVals[1]
  });

  const [setTouched] = useState(false);

  const handleUpdate = ([min, max]) => {
    setTouched(true);
    setDraftVal({ min, max });

    clearTimeout(window.timeoutHandle);
    window.timeoutHandle = window.setTimeout(() => {
      applyFilter();
    }, 1000);
  };

  const applyFilter = () => {
    setTouched(false);
    apply(draftVal);
  };

  let prefix = null;
  let suffix = null;

  // Add suffix to Price
  if (filter.id.includes('price')) {
    suffix = 'kr';
  }

  return (
    <Fragment>
      <RangeSliderContainer className={wrapper}>
        <div className="active-vals">
          <span>
            {prefix}
            {numberWithSeparator(draftVal.min, ' ')}
            {suffix}
          </span>
          <span>
            {prefix}
            {numberWithSeparator(draftVal.max, ' ')}
            {suffix}
          </span>
        </div>

        <ReactSlider
          disabled={filter.min === filter.max}
          min={filter.min}
          max={filter.max}
          minDistance={10}
          defaultValue={selectedVals}
          onChange={handleUpdate}
          value={[draftVal.min, draftVal.max]}
          withBars
        />
      </RangeSliderContainer>
    </Fragment>
  );
}
