import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { ReactComponent as HeartIcon } from '../../../../svg/heart.svg';
import { theme } from '../../../Theme';

const HeartAnimation = styled('span')`
  @keyframes fade {
    from {
      opacity: 1;
      transform: translateY(-1.5rem) scale(100%);
    }
    to {
      opacity: 0;
      transform: translateY(0.3rem) scale(0%);
    }
  }
  display: flex;
  justify-content: center;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
  animation: fade 0.5s linear forwards;
  animation-delay: 0.18s;

  svg {
    color: ${theme.colors.text};
    path {
      fill: currentColor;
      fill-opacity: 1 !important;
    }
  }
`;

export const HeartAnimations = () => {
  const { count } = useProductList();
  const [heartAnimations, setHeartAnimations] = useState([]);
  const [oldCount, setOldCount] = useState(count);

  useEffect(() => {
    if (oldCount < count) {
      setHeartAnimations([...heartAnimations, { key: Date.now() }]);
    }
    setOldCount(count);
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeAnimation = key => {
    const animations = heartAnimations.filter(
      animation => animation.key !== key
    );
    setHeartAnimations(animations);
  };

  return heartAnimations.map(({ key }) => {
    return (
      <HeartAnimation
        key={key}
        onAnimationEndCapture={() => removeAnimation(key)}
      >
        <HeartIcon />
      </HeartAnimation>
    );
  });
};
