import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

const useShopLogo = () => {
  const {
    apolloConfig: { shopid },
    logos
  } = useShopConfig();

  return logos[shopid];
};

export { useShopLogo };
