import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import UIButton from '@jetshop/ui/Button';
import Link from '../utility/Link';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

export const ButtonStyle = `
  display: inline-block;
  width: auto;
  height: auto;
  border: 1px solid currentColor;
  color: ${theme.colors.white};
  background: ${theme.colors.slategrey};
  border-color: ${theme.colors.slategrey};
  text-align: center;
  text-decoration: none !important;
  overflow: hidden;
  box-shadow: 0;
  font-family: ${theme.fonts.heading};
  cursor: pointer;
  transition: all 450ms ease;
  letter-spacing: 0.5px;

  &:disabled,
  &:hover {
    color: ${theme.colors.slategrey};
    background: transparent;
    border-color: ${theme.colors.slategrey};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.isActive {
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.white};
  }

  &.white {
    color: ${theme.colors.black};
    border-color: ${theme.colors.white};
    background: ${theme.colors.white};
  }

  &.white:hover {
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
    background: transparent;
  }

  &.dark {
    color: ${theme.colors.white};
  }

  &.dark {
    &:disabled,
    &:hover {
      color: ${theme.colors.white};
      border-color: ${theme.colors.black};
      background: ${theme.colors.black};
    }

    &.isActive {
      color: ${theme.colors.white};
      background: ${theme.colors.black};
      border-color: ${theme.colors.black};
    }
  }
`;

const Wrapper = styled('div')`
  position: relative;
  min-width: 120px;
  padding: 8px 22px;
`;

const Foreground = styled('span')`
  position: relative;
`;

const StyledButton = styled(UIButton)`
  ${ButtonStyle}

  &:focus,
  .inner:focus {
    outline: none;
  }

  &:focus > .inner {
    box-shadow: 0 0 2px 2px #51a7e8;
  }
`;

const StyledLink = styled(Link)`
  ${ButtonStyle};
`;

/*==============================================================================
  # Main Button
==============================================================================*/

export const ButtonInner = ({ children }) => (
  <Wrapper className="inner" tabIndex="-1">
    <Foreground>{children}</Foreground>
  </Wrapper>
);

export const ButtonLink = ({
  children,
  className,
  dark,
  grey,
  white,
  isActive,
  ...props
}) => {
  let classes = className;
  if (dark) classes += ' dark';
  // if (grey) classes += ' grey';
  if (isActive) classes += ' isActive';
  if (white) classes += ' white';

  return (
    <StyledLink className={classes} {...props}>
      <ButtonInner>{children}</ButtonInner>
    </StyledLink>
  );
};

const Button = ({
  children,
  loading,
  loadingText = t('Hold on a moment...'),
  className,
  dark,
  grey,
  white,
  isActive,
  ...props
}) => {
  let classes = className;
  if (dark) classes += ' dark';
  // if (grey) classes += ' grey';
  if (isActive) classes += ' isActive';
  if (white) classes += ' white';

  return (
    <StyledButton loading={loading} className={classes} {...props}>
      <ButtonInner>{loading ? loadingText : children}</ButtonInner>
    </StyledButton>
  );
};

export default Button;
