import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import LinkGroup from './LinkGroup';
import { theme } from '../../Theme';
import { ReactComponent as Facebook } from '../../../svg/facebook.svg';
import { ReactComponent as Instagram } from '../../../svg/instagram.svg';
import { ReactComponent as Pinterest } from '../../../svg/pinterest.svg';
import { ReactComponent as Youtube } from '../../../svg/youtube.svg';
import { ReactComponent as LinkedIn } from '../../../svg/linkedin.svg';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;

  ${theme.above.md} {
    margin: 0px -30px;
  }

  ${theme.above.lg} {
    margin: 0px -45px;
  }

  ${theme.above.xl} {
    margin: 0px -50px;
  }
`;

const FooterLinkGroup = styled(LinkGroup)`
  width: 100%;

  ${theme.above.xs} {
    width: 50%;
  }

  ${theme.above.md} {
    width: 25%;
    padding: 0px 30px 45px;

    &:not(:last-of-type) {
      border-right: 1px solid ${theme.colors.white};
    }
  }

  ${theme.above.lg} {
    padding: 0px 45px 45px;
  }

  ${theme.above.xl} {
    padding: 0px 50px 45px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const icons = {
  Facebook: <Facebook />,
  Instagram: <Instagram />,
  Pinterest: <Pinterest />,
  Youtube: <Youtube />,
  LinkedIn: <LinkedIn />
};

const FooterLinks = ({
  className,
  linkGroups,
  socialmediaTitle,
  socialmedia
}) => {
  let socialmediaDisplay = [];

  if (socialmedia) {
    JSON.parse(socialmedia).forEach(key => {
      const social = Object.entries(key);
      const [media, to] = social[0];
      if (to) {
        let item = {
          title: media,
          to,
          target: true,
          icon: media ? icons[media] : null
        };

        socialmediaDisplay.push(item);
      }
    });
  }

  return (
    <Wrapper className={className}>
      {linkGroups &&
        linkGroups.map(({ links }, i) => (
          <FooterLinkGroup key={i} links={links} />
        ))}

      <FooterLinkGroup
        title={socialmediaTitle || t('Följ oss')}
        links={socialmediaDisplay}
      />
    </Wrapper>
  );
};

export default FooterLinks;
