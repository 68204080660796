import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { css } from 'linaria';
import { Link } from 'react-router-dom';

import { theme } from '../../Theme';
import { useIntervalTimer } from '../../utility/hooks/useIntervalTimer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';

const Container = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #414c52;
  padding: 0.3rem 10px;
  font-family: ${theme.fonts.primary};
  font-size: 0.7125rem;
  height: 30px;
  text-transform: uppercase;

  ${theme.above.sm} {
    padding: 0.3rem 15px;
  }

  ${theme.above.md} {
    padding: 0.3rem 32px;
  }

  .usp-flip {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    color: ${theme.colors.white};

    span {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      opacity: 0;
      transform: translate(-50%, -1em);
      transition: opacity 1s, transform 1s;

      &.active {
        opacity: 1;
        transform: translate(-50%, 0);
        transition: opacity 1s;
        transition-delay: 1s;
      }
    }
  }

  ul {
    display: flex;
    position: relative;
  }

  li + li {
    margin-left: 15px;
  }

  ${theme.below.lg} {
    ul.static-links {
      display: none;
    }
  }
`;

const linkStyle = css`
  color: ${theme.colors.white} !important;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const USPBar = ({ usps }) => {
  const { routes } = useShopConfig();
  const { iteration, createTimer, clearTimer } = useIntervalTimer(3000);
  const { loggedIn } = useAuth();

  return (
    <Container onMouseOver={clearTimer} onMouseOut={createTimer}>
      <div className="usp-flip">
        {usps?.map((usp, i) => (
          <span
            className={cx(i === iteration % usps.length && 'active')}
            key={`usp${i}`}
          >
            {usp}
          </span>
        ))}
      </div>
      <ul className="static-links">
        <li>
          <Link to={routes.customerService.path} className={linkStyle}>
            {t('Customer service')}
          </Link>
        </li>
        <li>
          {loggedIn ? (
            <Link to={routes.myPages.path} className={linkStyle}>
              {t('My Pages')}
            </Link>
          ) : (
            <Link to={routes.login.path} className={linkStyle}>
              {t('Log in')}
            </Link>
          )}
        </li>
      </ul>
    </Container>
  );
};

export default USPBar;
