import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import Price from '../Price';
import { Heading, SubHeading } from '../ui/Headings';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const wrapperStyling = css`
  position: relative;
  padding: 5px;
  ${theme.fontSizes.regular}
  font-family: ${theme.fonts.heading};

  ${theme.above.md} {
    padding-bottom: 30px;
  }

  .badge-container {    
    > div > div {
      width: 35px;
      height: 35px;
    }

    img {
      max-width: 35px;
      max-height: 35px;
    }
  }

  .favourite {
    position: absolute;
    top: 0;
    right: 0;
    top: 5px;
    right: 5px;

    ${theme.above.md} {
      top: 15px;
      right: 15px;
    }

    button {
      display: block;
      display: flex !important;
      align-items: flex-start;
      justify-content: flex-end;
      width: 60px;
      height: 60px;
    }

    svg {
      display: block;
      height: 18px;
      width: 18px;
    }
  }

  .inner-card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    color: inherit;
    margin: 0;
    border-radius: 2px;
    padding: 5px;
    color: ${theme.colors.textgrey};
    transition: all 350ms ${theme.easings.secondary};

    ${theme.above.sm} {
      padding: 15px;
    }

    ${theme.above.md} {
      padding-bottom: 30px;
    }

    &:hover {
      color: ${theme.colors.slategrey};

      ${theme.above.md} {
        box-shadow: ${theme.styles.boxshadow};
      }

      .product-card-detail {
        border-color: ${theme.colors.black};
      }

      a {
        color: ${theme.colors.slategrey};
      }

      .name,
      .sub-name{
        color: ${theme.colors.black};
      }
    }
  }

  a {
    text-decoration: none;
    color: ${theme.colors.textgrey};
    transition: all 350ms ${theme.easings.secondary};
  }

  button {
    outline: none;
  }

  .image-container {
    position: relative;
    padding-top: 18px;
    padding-bottom: 30px;

    ${theme.above.md} {
      padding-top: 25px;
    }
  }

  .comment {
    position: absolute;
    bottom: 5px;
    right: 0px;
    z-index: 1;
  }

  .product-card-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    border-top: 1px solid ${theme.colors.textgrey};
    transition: all 350ms ${theme.easings.secondary};

    header {
      margin-top: 0px;
      padding: 15px 0px 10px;
    }

    .brand,
    .name,
    .sub-name {
      line-height: 1.1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: all 350ms ${theme.easings.secondary};
    }

    .name,
    .sub-name {
      color: ${theme.colors.slategrey};
    }

    .brand,
    .sub-name {
      height: 21px;
      ${theme.fontSizes.description}

      ${theme.above.sm} {
        height: 25px;
      }

      ${theme.above.md} {
        ${theme.fontSizes.regular}
      }
    }

    .name {
      height: 25px;
      text-transform: none;

      ${theme.above.sm} {
        height: 26px;
      }

      ${theme.below.md} {
        ${theme.fontSizes.regular}
      }

      span {
        text-transform: uppercase;
      }
    }
    
    .sub-name {
      text-transform: none;
    }

    .brand {
      margin-bottom: 5px;

      ${theme.above.md} {
        margin-bottom: 10px;
      }
    }

    .price {
      height: 25px;

      ${theme.above.sm} {
        height: 28px;
      }
    }
  }
`;

const StyledPrice = styled(Price)`
  display: flex;
  align-items: baseline;
  line-height: 1.1;
  ${theme.fontSizes.regular}
  ${theme.above.md} {
    ${theme.fontSizes.subHeading}
    text-transform: initial;
  }
  ${Price.Normal} {
    font-size: 1.25em;
    color: ${theme.colors.black};
  }
  ${Price.New},
  ${Price.Old} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${theme.colors.black};
  }

  ${Price.New} {
    font-size: 1.25em;
    margin-right: 8px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  ...linkProps
}) {
  const Tag = as;
  const {
    images,
    name,
    customFields,
    badges,
    hasVariants,
    price,
    previousPrice,
    canonicalCategory
  } = product;
  const hasImages = images && images.length > 0;
  const comment = t('Fler varianter');

  let brand = null;
  let subName = null;
  let hashasVariantsUpdated = hasVariants;
  let title = canonicalCategory
    ? `${name} - ${canonicalCategory.name}`
    : `${name}`;

  if (customFields) {
    customFields.forEach(item => {
      if (['Varumarke', 'varianter', 'Undernamn'].indexOf(item.key) >= 0) {
        if (item.key === 'Varumarke') {
          brand = item;
        } else if (item.key === 'varianter' && item.value) {
          hashasVariantsUpdated = true;
        } else if (item.key === 'Undernamn' && item.value) {
          subName = item.value;
        }
      }
    });
  }

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <div className="inner-card">
        <ProductLink product={product} title={title}>
          <div className="image-container">
            {hasImages ? (
              <Image
                sizes={imageSizes}
                aspect={imageAspect}
                alt={images[0].alt ? images[0].alt : title}
                src={images[0].url}
                modifiedDate={images[0].modifiedDate}
              />
            ) : (
              <Image src={transparentDataImg} />
            )}
            <div className="badge-container">
              <Badges badges={badges} />
            </div>
            {hashasVariantsUpdated && <div className="comment">{comment}</div>}
          </div>

          <section className="product-card-detail">
            <header>
              <SubHeading size="h4" className="brand">
                {brand ? brand.value : null}
              </SubHeading>
              <Heading size="h3" className="name">
                <span>{name}</span>
                {canonicalCategory ? ` - ${canonicalCategory.name}` : ''}
              </Heading>
              <SubHeading title={subName} size="h4" className="sub-name">
                {subName}
              </SubHeading>
            </header>
            <StyledPrice price={price} previousPrice={previousPrice} />
          </section>
        </ProductLink>

        {children}
      </div>
    </Tag>
  );
}
