import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import Container from '../Layout/Container';
import { Mega } from '../ui/Headings';
import Text from '../ui/Text';
import Link from '../utility/Link';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Plate = styled(Container)`
  color: ${theme.colors.slategrey};
  background-color: ${theme.colors.bggrey};
  padding: 30px;

  ${theme.above.md} {
    padding: 60px 80px;
  }

  ${theme.above.xl} {
    padding: 80px 100px;
  }

  .title {
    margin-bottom: 30px;
  }
`;

const StyledText = styled(Text)`
  ${theme.fontSizes.description}
`;

const MockLink = styled('div')`
  margin-top: 30px;
  text-align: right;
  font-family: ${theme.fonts.heading};
  ${theme.fontSizes.heading}
  text-transform: none;
`;

/*==============================================================================
  # Component
==============================================================================*/

const LinkWrapper = ({ to, children, ...rest }) =>
  to ? (
    <Link to={to} {...rest} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  ) : (
    <Fragment>{children}</Fragment>
  );

const TextPlate = ({ id, marginTop = true, title, text, link, ...rest }) => {
  return (
    <Container id={id} fullWidth={true} marginTop={marginTop} {...rest}>
      <Container>
        <LinkWrapper {...link}>
          <Plate>
            <Mega className="title">{title}</Mega>
            <StyledText content={text} full={true} />
            {link && <MockLink className="mock-link">{link.title}</MockLink>}
          </Plate>
        </LinkWrapper>
      </Container>
    </Container>
  );
};

export default TextPlate;
