import { css } from 'linaria';
import React from 'react';
import { DropdownMenu } from '@jetshop/ui/DropdownMenu';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const menu = css`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  color: ${theme.colors.slategrey};
  font-family: ${theme.fonts.heading};

  ${theme.above.md} {
    margin-bottom: 30px;
  }

  [data-flight-dropdown-button] {
    background-color: ${theme.colors.white};
  }

  [data-flight-dropdown-items] {
    position: static;
    width: 100%;
    margin-top: -1px;
    border: 1px solid ${theme.colors.slategrey};
    background: ${theme.colors.white};
    max-height: 300px;
    overflow: auto;
    z-index: 999;

    ${theme.above.md} {
      position: absolute;
      top: 100%;
      left: 15px;
      width: calc(100% - 30px);
    }
  }

  [data-flight-dropdown-item] {
    position: relative;
    border: 0px;
    padding: 10px 40px 10px 16px;
    color: ${theme.colors.slategrey};
    outline: none;
    overflow: hidden;

    &:hover {
      color: currentColor;
      background: ${theme.colors.lightgrey};
    }

    .icon {
      display: inline-block;
      width: 24px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      margin-bottom: -3px;

      > div > div {
        font-size: 0px !important;

        &::before {
          display: none;
        }
      }
    }

    span:not(.icon):not(.checkbox) {
      width: 100%;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .icon + span {
      width: calc(100% - 35px) !important;
    }

    .checkbox {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 16px;
      height: 16px;
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.slategrey};
      transition: all 70ms ease;
      transform: translateY(-50%);

      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 45%;
        left: 7%;
        width: 45%;
        height: 80%;
        max-width: 0%;
        max-height: 0%;
        opacity: 0;
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: ${theme.colors.slategrey};
        transform-origin: left bottom;
        transform: rotate(45deg);
        transition: all 0ms ease;
      }

      &.choosen {
        background: ${theme.colors.grey};

        &::after {
          max-width: 50%;
          max-height: 80%;
          opacity: 1;
          transition: opacity 0ms linear 0ms, max-width 35ms ease-in 0ms,
            max-height 35ms ease-out 35ms;
        }
      }
    }

    :hover,
    :focus {
      outline: none;
    }
  }

  [data-flight-dropdown-button] {
    position: relative;
    width: 100%;
    padding: 9px 16px;
    padding-right: 42px;
    border: 1px solid ${theme.colors.slategrey};
    border-radius: 0px;
    color: ${theme.colors.slategrey};
    ${theme.fontSizes.regular}
    text-decoration: none;
    text-align: left;
    outline: none;

    > p {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .marker {
      display: block;
      position: absolute;
      top: 50%;
      right: 16px;
      width: 8px;
      height: 8px;
      border-right: 2px solid ${theme.colors.slategrey};
      border-bottom: 2px solid ${theme.colors.slategrey};
      transform: translateY(calc(-50% - 3px)) rotate(45deg);
    }

    &[aria-expanded='true'] {
      .marker {
        transform: translateY(calc(-50% - 1px)) rotate(-135deg);
      }
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export function FilterDropdownStyles(props) {
  return <DropdownMenu className={menu} {...props} />;
}
