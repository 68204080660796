import React from 'react';
import { styled } from 'linaria/react';
import { Heading } from '../../ui/Headings';
import Link from '../../utility/Link';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledLinkGroup = styled('div')`
  padding: 15px;
  margin-bottom: 10px;

  ${theme.below.sm} {
    text-align: center;
  }

  ul {
    width: 100%;
  }

  li,
  .title {
    margin-bottom: 5px;
  }

  a {
    ${theme.styles.linkEase}
    ${theme.fontSizes.heading}
    font-size: 1.125rem;
    color: ${theme.colors.white} !important;
    outline: none;
    text-transform: uppercase;
    text-decoration: none;

    svg {
      width: 20px;
      height: 20px;
      margin-top: -4px;
      margin-right: 12px;
      color: currentColor;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const LinkGroup = ({ className, title, links }) => {
  return (
    <StyledLinkGroup className={className}>
      {title && <Heading className="title">{title}:</Heading>}

      <ul>
        {links &&
          links.map((link, i) => {
            return (
              <li key={i}>
                <Link {...link}>
                  {link.icon}
                  <span dangerouslySetInnerHTML={{ __html: link.title }} />
                </Link>
              </li>
            );
          })}
      </ul>
    </StyledLinkGroup>
  );
};

export default LinkGroup;
