import React from 'react';
import { styled } from 'linaria/react';
import Container from '../Layout/Container';
import EntryCard, { EntryContainer } from '../ui/EntryCard';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledEntryContainer = styled(EntryContainer)`
  display: grid;
  grid-gap: 5px;
  margin-bottom: -30px;
  grid-template-columns: repeat(3, 1fr);
  ${theme.below.md} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledEntryCard = styled(EntryCard)`
  ${theme.below.md} {
    margin: 0;
    margin-bottom: 15px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const Entries = ({ id, marginTop, background, entries }) => {
  return (
    <Container
      id={id}
      fullWidth={true}
      background={background}
      marginTop={marginTop}
    >
      <Container>
        <StyledEntryContainer>
          {entries &&
            entries.length > 0 &&
            entries.map((entry, i) =>
              entry ? (
                <StyledEntryCard
                  key={i}
                  topTitle={entry.topTitle}
                  title={entry.title}
                  {...entry.image}
                  sizes={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 3]}
                  link={{
                    title:
                      entry.link && entry.link.title ? entry.link.title : '',
                    to: entry.link && entry.link.to ? entry.link.to : '/',
                    dark: true
                  }}
                />
              ) : null
            )}
        </StyledEntryContainer>
      </Container>
    </Container>
  );
};

export default Entries;
