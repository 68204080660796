import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import Container, { Row, Col } from '../../Layout/Container';
import FilterDrawer from './FilterDrawer';
import { ButtonFilters, ButtonFilterContainer } from './ButtonFilters';
import { SortDropdown } from './SortDropdown';
import { BooleanFilters } from './BooleanFilters';
import { ListFilters } from './ListFilters';
import { MultiListFilters } from './MultiListFilters';
import { RangeFilters } from './RangeFilters';
import { ButtonStyle } from '../../ui/Button';
import { theme } from '../../Theme';
import { ReactComponent as Icon } from '../../../svg/filter.svg';

/*==========================================================q====================
  # Styles
==============================================================================*/

const ButtonFiltersWrapper = styled('div')`
  padding-bottom: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${theme.colors.slategrey};
`;

const ButtonCategoryLink = styled(CategoryLink)`
  ${ButtonStyle}
  display: inline-block;
  height: auto;
  width: auto;
  max-width: none;
  margin: 5px;
  padding: 10px 25px;
  text-transform: uppercase;
  color: ${theme.colors.white};
  border-color: ${theme.colors.black};

  &:active,
  &:hover {
    color: ${theme.colors.slategrey};
    border-color: ${theme.colors.slategrey};
    background-color: transparent;
  }

  ${theme.above.md} {
    margin: 5px 10px;
  }
`;

const FilterCol = styled('div')`
  width: 66.66%;
  display: flex;
  justify-content: flex-start;

  > div {
    width: 50%;
  }

  ${theme.above.lg} {
    width: 75%;

    > div {
      width: 33.33%;
    }
  }

  ${theme.above.xl} {
    width: 80%;

    > div {
      width: 25%;
    }
  }
`;

const SortCol = styled(FilterCol)`
  width: 33.33%;

  > div {
    width: 100%;
  }

  ${theme.above.lg} {
    width: 25%;
  }

  ${theme.above.xl} {
    width: 20%;
  }
`;

const Scrollbars = styled('div')`
  overflow-y: auto;
  height: 100%;
`;

const MobileButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 15px;
  bottom: 15px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.slategrey};
  text-align: center;
  outline: none;
  z-index: 98;

  svg {
    width: 17px;
    height: 17px;
    margin-bottom: 4px;
    transition: all 250ms ease;
    color: ${theme.colors.white};

    path {
      fill: currentColor;
    }
  }

  p {
    font-size: 10px;
    transition: all 250ms ease;
  }

  &::after,
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 2px;
    background-color: ${theme.colors.white};
    transition: all 250ms ease;
    transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }

  &.isOpen {
    p,
    svg {
      transform: scale(0);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg) scale(1);
    }
  }
`;

const CustomDrawer = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  outline: 0;
  transition: all 250ms ease;
  background-color: rgba(0, 0, 0, 0);
  visibility: hidden;
  z-index: 97;

  .inner {
    position: absolute;
    bottom: -100%;
    width: 100%;
    height: 70%;
    overflow: hidden;
    background-color: ${theme.colors.grey};
    transition: all 250ms ease;
  }

  &.open {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);

    .inner {
      bottom: 0px;
    }
  }
`;

const ClearButton = styled('button')`
  ${theme.fontSizes.description}
  color: ${theme.colors.slategrey};
  background-color: transparent;
  text-decoration: underline;
  margin-left: 15px;
  margin-bottom: 10px;

  ${theme.above.md} {
    margin-left: 0px;
    margin-bottom: 0px; 
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const TopFilterSwitch = ({ topFilter, productsCategoryId, category }) => {
  // NOTE: Forcing category filters to always show as links. Otherwise deep down
  // these button actions would be applied as filters, which SH does not want currently.
  // remove 'alwaysShowAsLinks' from lines below to revert to it's previous behavior.
  const alwaysShowAsLinks = true;
  if (
    alwaysShowAsLinks ||
    (category && category.parent && category.parent.id === productsCategoryId)
  ) {
    return category?.subcategories?.length > 1 ? (
      <ButtonFiltersWrapper>
        <ButtonFilterContainer>
          {category.subcategories.map((subCat, i) => (
            <ButtonCategoryLink key={i} category={subCat} />
          ))}
        </ButtonFilterContainer>
      </ButtonFiltersWrapper>
    ) : null;
  }

  return topFilter &&
    topFilter[0] &&
    topFilter[0].items &&
    topFilter[0].items.length > 1 ? (
    <ButtonFiltersWrapper>
      <ButtonFilters filters={topFilter} />
    </ButtonFiltersWrapper>
  ) : null;
};

export const ClearFilters = ({ filters }) => {
  const { clearAllFilters } = useFilters();
  if (!filters) return null;

  const activeListFilters = filters.filter(listFilter => {
    return listFilter.hasActiveItems;
  });
  if (activeListFilters.length === 0) return null;

  return (
    <ClearButton onClick={clearAllFilters}>
      {t('Clear all filters')}
    </ClearButton>
  );
};

class Filter extends Component {
  state = {
    isOpen: false
  };

  toggleMenu = () => {
    let { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  };

  closeMenu = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    let { isOpen } = this.state;
    let { filters, category, productsCategoryId } = this.props;
    let topFilter = filters.filter(filter => filter.name === 'Produkttyp');
    const sortOrders = this.props.sortOrders || [];
    let cloneFilters = [...filters];

    return (
      <Fragment>
        <Container fullWidth={true}>
          <TopFilterSwitch
            topFilter={topFilter}
            productsCategoryId={productsCategoryId}
            category={category}
          />
        </Container>

        <Above breakpoint="md">
          {matches =>
            matches ? (
              <Container marginTop={false} fullWidth={true}>
                <Row>
                  <FilterCol>
                    <ListFilters
                      filters={cloneFilters.filter(
                        filter => filter.name !== 'Produkttyp'
                      )}
                    />
                    <MultiListFilters
                      filters={cloneFilters.filter(
                        filter => filter.name !== 'Produkttyp'
                      )}
                    />
                    <RangeFilters filters={cloneFilters} />
                  </FilterCol>

                  <SortCol>
                    <SortDropdown sortOrders={sortOrders} />
                  </SortCol>
                </Row>

                <Row justify="flex-start">
                  <div>
                    <BooleanFilters filters={cloneFilters} />
                  </div>
                </Row>

                <Row justify="flex-start">
                  <Col col={12}>
                    <ClearFilters filters={cloneFilters} />
                  </Col>
                </Row>
              </Container>
            ) : (
              <Fragment>
                <MobileButton
                  id="filter-toggle-btn"
                  onClick={this.toggleMenu}
                  className={isOpen ? 'isopen' : ''}
                >
                  <Icon />
                  <p>{t('Filter')}</p>
                </MobileButton>

                <CustomDrawer className={isOpen ? ' open' : ''}>
                  <div className="inner">
                    <Scrollbars>
                      <FilterDrawer
                        close={() => this.closeMenu()}
                        filters={cloneFilters.filter(
                          filter => filter.name !== 'Produkttyp'
                        )}
                        category={category}
                        sortOrders={sortOrders}
                      />
                    </Scrollbars>
                  </div>
                </CustomDrawer>
              </Fragment>
            )
          }
        </Above>
      </Fragment>
    );
  }
}

export default Filter;
