import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  background: transparent;
`;

const Form = styled('form')`
  flex-grow: 1;

  input {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 15px 30px;
    border: 0px;
    border-radius: 0px;
    border: 1px solid ${theme.colors.black};
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    box-shadow: none;
    outline: 0;
    ${theme.fontSizes.regular}

    &::placeholder {
      color: ${theme.colors.slategrey};
    }

    ${theme.below.sm} {
      width: 100%;
      text-align: center;
      border-bottom: 0px;
    }

    ${theme.above.sm} {
      border-right: 0px;
    }

    ${theme.above.md} {
      &.large {
        padding: 25px;
      }
    }

    ${theme.above.lg} {
      &.large {
        padding: 50px;
        ${theme.fontSizes.heading}
      }
    }
  }
`;

const InputWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  ${theme.below.sm} {
    flex-wrap: wrap;
  }
`;

const Button = styled('button')`
  display: block;
  height: 100%;
  padding: 15px 30px;
  outline: none;
  box-shadow: none;
  color: ${theme.colors.white};
  background-color: ${theme.colors.black};
  border: 1px solid ${theme.colors.black};
  transition: all 250ms ${theme.easings.secondary};
  ${theme.fontSizes.regular}

  ${theme.below.sm} {
    width: 100%;
  }

  ${theme.above.md} {
    &.large {
      padding: 25px 50px;
    }
  }

  ${theme.above.lg} {
    &.large {
      padding: 50px 100px;
      ${theme.fontSizes.heading}
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;

const CheckboxLabel = styled('label')`
  position: relative;
  display: inline-block;
  margin-top: 10px;
  padding-left: 30px;
  cursor: pointer;

  .text {
    user-select: none;

    a {
      color: ${theme.colors.black};
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  .checkbox {
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 16px;
    height: 16px;
    border: 2px solid ${theme.colors.black};
    transform: translateY(-50%);

    &:before {
      display: block;
      content: '';
      position: absolute;
      bottom: 50%;
      left: 7%;
      width: 45%;
      height: 80%;
      max-width: 0%;
      max-height: 0%;
      opacity: 0;
      border-width: 0px 2px 2px 0px;
      border-style: solid;
      border-color: ${theme.colors.black};
      transform-origin: left bottom;
      transform: rotate(45deg);
      transition: all 0ms ease;
    }
  }

  //Marking css for new checkbox
  input[type='checkbox']:checked ~ .checkbox:before {
    max-width: 45%;
    max-height: 80%;
    opacity: 1;
    transition: opacity 0ms linear 0ms, max-width 35ms ease-in 0ms,
      max-height 35ms ease-out 35ms;
  }
`;

const Message = styled('div')`
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  color: ${theme.colors.black};
  background-color: ${theme.colors.lightgrey};
  box-shadow: ${theme.styles.boxshadow};
`;

const Error = styled(Message)`
  border-left: 10px solid ${theme.colors.red};
  margin-bottom: 10px;
`;

const Sucess = styled(Message)`
  border-left: 10px solid ${theme.colors.blue};
`;

/*==============================================================================
  # Component
==============================================================================*/

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    confirmed: false,
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  updateConfirmation = e => this.setState({ confirmed: !this.state.confirmed });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    let { confirmed } = this.state;
    let { placeholder, inputId, confirmText, large } = this.props;

    placeholder = placeholder ? placeholder : 'E-mail...';
    confirmText = confirmText
      ? confirmText
      : t(
          'Jag godkänner härmed att Svenska Hem får samla in, använda och behandla mina personuppgifter som jag har angivit i detta formulär i enlighet med vår integritetspolicy.'
        );

    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <Wrapper>
              {this.state.error && (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? t('Du är redan en prenumerant av det här nyhetsbrevet!')
                    : t(
                        'Något gick fel. Kontrollera din e-postadress och försök igen.'
                      )}
                </Error>
              )}

              {this.state.completed ? (
                <Sucess>
                  {t('Tack för din prenumeration, vi hörs snart!')}
                </Sucess>
              ) : (
                <Form
                  className={large ? 'large' : ''}
                  onSubmit={submitEmail(this.state.email, subscribe)}
                >
                  <InputWrapper>
                    <input
                      type="email"
                      id={inputId}
                      placeholder={placeholder}
                      onChange={this.updateEmail}
                      style={{
                        paddingRight: result.loading ? '8rem' : '4rem'
                      }}
                    />
                    <Button
                      type="submit"
                      aria-label="Skicka"
                      className={large ? 'large' : ''}
                      disabled={!confirmed}
                    >
                      {t('Skicka')}
                    </Button>
                  </InputWrapper>

                  <CheckboxLabel
                    htmlFor={`checkbox-${inputId}`}
                    className="description-size"
                  >
                    <input
                      id={`checkbox-${inputId}`}
                      type="checkbox"
                      onChange={this.updateConfirmation}
                    />
                    <span className="checkbox" />
                    <span className="text">{confirmText}</span>
                  </CheckboxLabel>
                </Form>
              )}
            </Wrapper>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
