// generate random key
export const randomKey = () => `${Date.now()}_${Math.random()}`;

/*
 * HTML to JSON
 */
export function HTML2JSON(content, title = 'component') {
  let json = null;
  if (content) {
    try {
      json = JSON.parse(content.replace(/<(?:.|\n)*?>/gm, ''));
    } catch (e) {
      console.log(
        `Error with JSON data in ${title}. Validate json with https://jsonlint.com/`
      );
      console.log(e);
      return {};
    }
  }
  return json;
}

/*
 * hexToRgb
 */

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

/*
 * getObjectFromArrayByKeyValue
 */
export function getObjectFromArrayByKeyValue(nameKey, returnValue, myArray) {
  let returnArray = [];

  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i][nameKey] === returnValue) {
      returnArray.push(myArray[i]);
    }
  }

  return returnArray;
}

/*
 * numberWithSeparator
 */

export function numberWithSeparator(number, separator) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

/*
 * Shuffle array
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/*
 * Programaticly create image src to pub_docs
 */

export function pubDocsSrc(folder, name, fileType) {
  let filename = name
    .replace(/[åäÅÄ]/g, 'a')
    .replace(/[öÖ]/g, 'o')
    .replace(/[^a-z0-9_]+/gi, '-')
    .replace(/^-|-$/g, '')
    .toLowerCase();

  return `/pub_docs/files/${folder}/${filename}${
    fileType ? '.' + fileType : ''
  }`;
}

/*
 * Test if element is child of id or has id
 */

export function isDescendant(parentID, child) {
  var node = child.parentNode;
  if (child.id === parentID) {
    return true;
  }
  while (node != null) {
    if (node.id === parentID) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(null, args);
    }, timeout);
  };
};

export const debounceLeading = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(null, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
};

export const getCustomFieldValue = (key, customFields) => {
  if (!customFields?.length) return;
  const match = customFields.find(field => field.key === key);
  if (!match) return;
  let value;
  switch (match.type) {
    case 'STRING':
      value = match.stringValue;
      break;
    case 'BOOL':
      value = match.boolValue;
      break;
    case 'LIST':
      value = match.listValue;
      break;
    default:
      break;
  }
  if (!value) return;
  return { key, title: match.title, value };
};
