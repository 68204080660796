import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartItem from './CartItem';
import Price from '../Price';
import { ButtonLink } from '../ui/Button';
import CloseButton from '../ui/CloseButton';
import { Heading, SmallHeading } from '../ui/Headings';
import cartQuery from './CartQuery.gql';
import { theme } from '../Theme';
import FreeShipping from './FreeShipping';

/*==============================================================================
  # Styles
==============================================================================*/

const Flyout = styled('div')`
  background: ${theme.colors.white};
  color: ${theme.colors.black};
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const Section = styled('section')`
  padding: 15px;
`;

export const Header = styled(Section)`
  position: relative;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.grey};
`;

const SectionBorderTop = styled(Section)`
  border-top: 1px solid ${theme.colors.grey};
`;

const CartItems = styled(Section)`
  ${theme.above.md} {
    width: 100%;
  }
`;

const Summary = styled('div')`
  .small-heading-size {
    font-family: ${theme.fonts.primary};
  }

  ${theme.above.md} {
    width: 100%;

    > section:first-of-type {
      border-top: 0px;
    }
  }
`;

export const CloseBtn = styled(CloseButton)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const EmptyCart = styled('span')`
  display: block;
  text-align: center;
  margin: 15px 0px;
`;

const LightText = styled('span')`
  display: block;
  color: ${theme.colors.black};
  ${theme.fontSizes.description};
  font-family: ${theme.fonts.primary};
  font-weight: 700;
`;

export const LabelGroup = styled('div')`
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .bold {
    font-weight: 700;
    color: ${theme.colors.black};
  }
`;

export const Label = styled('label')`
  flex-shrink: 0;
  padding-right: 15px;
  ${theme.fontSizes.regular}

  ${theme.below.md} {
    ${theme.fontSizes.description}
  }
`;

const CheckoutLink = styled(ButtonLink)`
  width: 100%;
  margin: 10px 0px;
`;

/*==============================================================================
  # Component
==============================================================================*/

const DiscountRow = ({ discount }) => {
  const negativeDiscount = {};
  Object.entries(discount).forEach(row =>
    typeof row[1] === 'number'
      ? (negativeDiscount[row[0]] = row[1] * -1)
      : (negativeDiscount[row[0]] = row[1])
  );

  return <Price price={negativeDiscount} />;
};

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items || [];
  const itemCount = result?.data?.cart?.totalQuantity || 0;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts || [];
  const track = useTracker();

  return (
    <Fragment>
      <Flyout id="cart-window" {...rest}>
        <Header>
          <Heading>
            {t('Cart')}
            <CloseBtn
              onClick={() => {
                modal.hideTarget();
              }}
            />

            {items.length > 0 && <ItemCount count={itemCount} />}
          </Heading>
        </Header>

        {items.length === 0 ? (
          <Section>
            <EmptyCart>{t('Inga produkter i varukorgen.')}</EmptyCart>
          </Section>
        ) : (
          <div>
            <CartItems>
              {items.map(item => (
                <CartItem item={item} modal={modal} key={item.id} />
              ))}
            </CartItems>

            <Summary>
              {discounts.length > 0 && (
                <SectionBorderTop>
                  <SmallHeading>{t('Cart total')}</SmallHeading>

                  <LabelGroup>
                    <Label>{t('Order value')}</Label>
                    <Price price={result.data.cart.productPreviousTotal} />
                  </LabelGroup>

                  <SmallHeading>{t('Discounts')}</SmallHeading>

                  {discounts.map(discount => {
                    return (
                      <LabelGroup key={discount.name}>
                        <Label>{discount.name}</Label>
                        <DiscountRow discount={discount.value} />
                      </LabelGroup>
                    );
                  })}
                </SectionBorderTop>
              )}

              <SectionBorderTop>
                <FreeShipping cartTotal={result.data.cart.productTotal} />
                <LabelGroup>
                  <Label>{t('Total')}</Label>
                  <Price
                    className="bold"
                    price={result.data.cart.productTotal}
                  />
                </LabelGroup>

                {checkoutUrl && (
                  <CheckoutLink
                    to={checkoutUrl}
                    dark={true}
                    style={{ marginTop: 25 }}
                    onClick={() => {
                      track(trackCartCheckoutEvent({ cart: result.data.cart }));
                    }}
                  >
                    {t('Check out')}
                  </CheckoutLink>
                )}
              </SectionBorderTop>
            </Summary>
          </div>
        )}
      </Flyout>
    </Fragment>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <Drawer isOpen={drawer.isOpen} right>
              <CartFlyoutView modal={drawer} result={result} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
