import React from 'react';
import { styled } from 'linaria/react';
import Spinner from './ui/Spinner';

const LoadingSectionWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 200px;

  @keyframes rotator {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(270deg);
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 80%;
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotator 1.4s linear infinite;
  }
`;

const LoadingPageWrapper = styled(LoadingSectionWrapper)`
  height: 100vh;
`;

export const LoadingSection = ({ ...rest }) => {
  return (
    <LoadingSectionWrapper {...rest}>
      <Spinner />
    </LoadingSectionWrapper>
  );
};

const LoadingPage = ({ ...rest }) => {
  return (
    <LoadingPageWrapper {...rest}>
      <Spinner />
    </LoadingPageWrapper>
  );
};

export default LoadingPage;
