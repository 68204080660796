import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import t from '@jetshop/intl';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { ProductCard } from '../CategoryPage/ProductCard';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import CloseBtn from '../ui/CloseButton';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const ExtraContent = styled('div')`
  margin-top: 20px;
`;

const removeStyles = css`
  position: absolute;
  top: 15px;
  right: 15px;

  &:hover {
    background-color: ${theme.colors.bggrey};
  }
`;

const invalidProductStyles = css`
  background: #e7e7e7;
  border: 0.75px solid #bfbdbd;
  text-align: center;
  padding: 1em;

  color: #555555;
`;

/*==============================================================================
  # Component
==============================================================================*/

export function Product({ product, loading, children }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  if (product.isVariant && product.variant.images) {
    productForDisplay.images = product.variant.images;
  }

  return (
    <Fragment>
      <ProductCard product={productForDisplay} as="div">
        <ExtraContent>
          {product.hasVariants && (
            <div className="select-variant">
              <SelectVariant product={product} />
            </div>
          )}
          {product.validation.status === 'valid' ? (
            <AddToCart product={product} />
          ) : (
            <InvalidProduct validation={product.validation} />
          )}
        </ExtraContent>
        <RemoveFromList
          articleNumber={product.articleNumber}
          variant={product.variant}
        />
      </ProductCard>
    </Fragment>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <CloseBtn
      className={removeStyles}
      aria-label="Trash"
      onClick={() => remove(articleNumber, { variantArticleNumber })}
    />
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Pre-order date required'),
    configurations: t('Visit product page to add')
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}
